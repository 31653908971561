import React, { Component, Fragment } from 'react'
import { SlideDown } from 'react-slidedown'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-slidedown/lib/slidedown.css'

import RequestDocument from './RequestDocument'
import DatePickerInput from '../DatePickerInput'
import SelectField from '../SelectField'
import DocumentModal from '../DocumentModal'
import UserSelect from '../UserSelect'
import If from '../if'

import { ptBR } from 'date-fns/locale'
import moment  from 'moment'

registerLocale('pt-BR', ptBR)
setDefaultLocale('pt-BR')

export default class List extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: true, //props.isRequestSpecified || props.formSent,
    }
  }

  _hasErrors = (field) => Object.keys(this.props.errors[field] || {}).length > 0

  _inputModifier = (...fields) => {
    const { formSent } = this.props
    const inputModifier = !fields.every(this._hasErrors) ? '-success' : '-error'
    return formSent ? inputModifier : ''
  }

  _onToggle = (e) => {
    e.preventDefault()
    this.setState(prevState => ({ visible: !prevState.visible }))
  }

  _onDelete = async(e) => {
    const { observation, onDelete } = this.props
    e.preventDefault()

    const result = await SweetAlert({
      title: 'Você tem certeza?',
      showCancelButton: true,
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Voltar'
    })

    if (result.value) {
      try {
        await axios.delete(`/request_documents/${observation.id}.json`)
        onDelete()
      } catch (_) {
        SweetAlert('Oops!', 'Ocorreu um erro para deletar a observação. Tente novamente mais tarde.')
      }
    }
  }

  _renderDocuments() {
    const { documents, canManageDocuments } = this.props

    return documents.map((document, index) => {
      return (
        <RequestDocument key={index} document={document} canManageDocument={canManageDocuments} />
      )
    })
  }

  render() {
    const { visible } = this.state
    const { isRequestSpecified, requestId, title, documents, authenticityToken } = this.props

    return isRequestSpecified ? (
      <Fragment>
        <h4>
          <span>Documentos da Demanda</span>

          <DocumentModal
            requestId={requestId}
            authenticityToken={authenticityToken}
            buttonText="📄 Anexar novo documento"
          />

          <button onClick={this._onToggle} className="btn -primary -small -spaced -withrighticon">
            {visible ? 'Ocultar' : 'Mostrar'}

            <span className="icon">
              {visible ? '▲' : '▼'}
            </span>
          </button>
        </h4>

        <SlideDown>
          <div className="data">
            <div className="request-list" id="attendances-table">
              <div className="table">
                <table className="content">
                  <If test={visible && documents.length > 0}>
                    <thead>
                      <tr className="line">
                        <td className="cell">Título</td>
                        <td className="cell -centered">Tipo</td>
                        <td className="cell -centered">Descrição</td>
                        <td className="cell -centered">Nº do Documento</td>
                        <td className="cell -centered">Responsável</td>
                        <td className="cell -centered">Data do Documento</td>
                        <td className="cell -centered">Data de Inclusão no Sistema</td>
                        <td className="cell -right"></td>
                      </tr>
                    </thead>
                    <tbody>
                      { this._renderDocuments() }
                    </tbody>
                  </If>
                  <If test={visible && documents.length == 0}>
                    <tbody>
                      <tr className="line">
                        <td className="cell">
                          Esta demanda não possui documentos
                        </td>
                      </tr>
                    </tbody>
                  </If>
                </table>
              </div>
            </div>
          </div>
        </SlideDown>
      </Fragment>
    ) : null
  }
}
