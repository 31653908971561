import React, { Component } from 'react'
import SlideDown from 'react-slidedown'
import Observation from './Observation'
import If from '../if'

export default class List extends Component {
  render() {
    const { elements, canManage, onEdit, onDelete } = this.props

    return (
      <SlideDown>
        <div className="header">
          <p />
          <If test={elements.length}>
            <p>Criado em</p>
          </If>
        </div>

        <SlideDown>
          {
            elements.map((observation, idx) => (
              <Observation
                key={idx}
                observation={observation}
                canManageObservation={canManage}
                onEditObservation={(obs) => onEdit(idx, obs)}
                onDelete={() => onDelete(idx)}
              />
            ))
          }
        </SlideDown>
      </SlideDown>
    )
  }
}
