import React, { Component } from 'react'
import SweetAlert from 'sweetalert2'
import IcCombine from '../images/ic-combine'

const getMeta = (name) => {
  const el = document.querySelector(`meta[name='${name}']`)
  if (el) return el.getAttribute("content")
}

export default class MergeTags extends Component {
  onCombine = async (e) => {
    const form = e.target
    e.preventDefault()

    const { tags, originTag } = this.props
    const options = tags.filter(({ id }) => id !== originTag)

    const { value: selectedOption } = await SweetAlert.fire({
      title: 'Combinar tags',
      text: 'Selecione com qual outra tag você deseja combinar esta tag.',
      input: 'select',
      inputOptions: options.map(({ label }) => label),
      showCancelButton: true,
      allowOutsideClick: true,
      cancelButtonText: 'Cancelar'
    })

    this.targetTagRef.value = options[selectedOption].id
    form.submit()
  }

  render() {
    const { target } = this.props

    const csrfToken = getMeta('csrf-token')
    const csrfParam = getMeta('csrf-param')

    return (
      <form action={target} method="POST" onSubmit={this.onCombine} style={{ display: 'inline' }}>
        {
          (csrfParam !== undefined && csrfToken !== undefined) && (
            <input name={csrfParam} value={csrfToken} type="hidden" />
          )
        }
        <input name="target_tag" type="hidden" ref={(ref) => this.targetTagRef = ref} />

        <button style={{
          display: 'inline',
          background: 'none',
          border: 0,
          margin: 0,
          padding: 0,
          cursor: 'pointer'
        }}>
          <IcCombine />
        </button>
      </form>
    )
  }
}
