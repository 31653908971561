import React, { useState } from 'react'
import { CirclePicker } from 'react-color'

export default ({ name, label, currentColor }) => {
  const [color, setColor] = useState(currentColor)

  return (
    <div className="input-group">
      <label className="label">{label}</label>
      <input type="hidden" name={name} value={color} />
      <CirclePicker
        color={color}
        onChangeComplete={(newColor) => setColor(newColor.hex)}
      />
    </div>
  )
}
