import $ from 'jquery'

$(() => {
  $('.excluirAgenda').on('click', (element) => {    
    var id = $(element.currentTarget).attr("id");
    if(confirm("Deseja realmente excluir a agenda e todos os eventos associados?")){
      $.ajax({
        url: "https://api.meubancodedados.com.br/api/Agenda/Excluir?Id=" + id,
        type: "GET",
        cache: false,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        async: true,
        success: function (data) {
          alert('Agenda excluída com sucesso!');
          window.location.reload();
        },
        error: function (jqXHR, textStatus, errorThrown) {

        }
      }).then((res) => {

      });
    }     
  });
});
