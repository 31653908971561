import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { Async as SelectAsync } from 'react-select'
import ReactSelectStyles from './styles/react-select'

export default class NetworkSelect extends Component {
  state = {
    selected: []
  }

  componentDidMount() {
    this._loadSelectedNetworks();
  }

  componentDidUpdate(prevProps, _prevState) {
    if (this.props.selected != prevProps.selected)
      this._loadSelectedNetworks();
  }

  _networkToOption = ({ id, name }) => ({ value: id, label: name })

  _optionsFromServerResponse = ({ data }) => data.map(this._networkToOption)

  _loadSelectedNetworks = () => {
    const { selected } = this.props

    const ids = selected instanceof Array ? selected.join(',') : selected

    if (!selected || selected.length === 0)
      return

    axios.get('/user_networks.json', { params: { ids } }).then((res) => (
      this.setState({ selected: this._optionsFromServerResponse(res) })
    ))
  }

  loadOptions = async (search) => {
    const res = await axios.get('/user_networks.json', { params: { search } })

    if (!search && res.data.length <= 1) {
      return []
    } else {
      return this._optionsFromServerResponse(res)
    }
  }

  handleChange = (selected) => {
    const { onChange } = this.props

    onChange && onChange(selected)

    this.setState({
      selected: selected instanceof Array ? selected : [selected]
    })
  }

  render() {
    const { selected } = this.state
    const { placeholder, multiple, name } = this.props
    const value = multiple ? selected : selected[0]
    console.log(value)
    return (
      <div className="input-group">
        <label className="label">{placeholder}</label>
        <SelectAsync
          placeholder="Selecione..."
          isMulti={multiple}
          value={value}
          cacheOptions
          defaultOptions
          loadOptions={this.loadOptions}
          onChange={this.handleChange}
          styles={ReactSelectStyles}
          noOptionsMessage={({ inputValue }) => (
            inputValue && inputValue.length > 0 ? "Nenhuma opção encontrada" : "Digite para iniciar a busca..."
          )}
          loadingMessage={() => "Buscando..."}
        />

        {
          selected.map(({ value }) => (
            <input key={value} type="hidden" name={name} value={value} />
          ))
        }
      </div>
    )
  }
}
