import React, { Component } from 'react'
import * as uuid from 'uuid'
import { Creatable as SelectCreatable } from 'react-select'
import ReactSelectStyles from './styles/react-select'
import IcDelete from '../images/ic-delete'
import IcArrowUp from '../images/ic-arrow-up'
import IcArrowDown from '../images/ic-arrow-down'

class SurveyQuestion extends Component {
  render() {
    const { question, first, last, onMoveDown, onMoveUp, onUpdate, onDelete } = this.props
    const field = (name) => `survey[questions][][${name}]`

    return (
      <div className="question">
        <input type="hidden" name={field('guid')} value={question.guid} />
        <div className="input-group -horizontal">
          <div className="input-group -stretched">
            <label className="label">Título</label>
            <input
              className="input"
              value={question.title}
              name={field('title')}
              onChange={(e) => onUpdate({ title: e.target.value })}
            />
          </div>
          <div className="input-group -stretched">
            <label className="label">Tipo</label>
            <select
              className="input"
              name={field('type')}
              onChange={(e) => onUpdate({ type: e.target.value })}
              value={question.type}
            >
              <option value="text">Texto</option>
              <option value="longtext">Texto Longo</option>
              <option value="number">Número</option>
              <option value="single">Escolha única</option>
              <option value="multi">Múltipla escolha</option>
            </select>
          </div>
          <div className="input-group actions">
            <button onClick={(e) => { e.preventDefault() ; onDelete() }}>
              <IcDelete width="16px" height="16px" />
            </button>
            {
              !first && (
                <button onClick={(e) => { e.preventDefault() ; onMoveUp() }}>
                  <IcArrowUp width="16px" height="16px" />
                </button>
              )
            }
            {
              !last && (
                <button onClick={(e) => { e.preventDefault() ; onMoveDown() }}>
                  <IcArrowDown width="16px" height="16px" />
                </button>
              )
            }
          </div>
        </div>
        {
          (question.type == 'single' || question.type == 'multi') && (
            <div className="input-group">
              <label className="label">Opções</label>
              {
                (question.options || []).map((option) => (
                  <input
                    type="hidden"
                    value={option}
                    name={`${field('options')}[]`}
                  />
                ))
              }
              <SelectCreatable
                isMulti
                placeholder=""
                styles={ReactSelectStyles}
                onChange={(selected) => onUpdate({ options: selected.map(({value}) => value) })}
                noOptionsMessage={() => null}
                formatCreateLabel={(opt) => `Adicionar opção "${opt}"`}
                value={(question.options || []).map((opt) => ({ value: opt, label: opt }))}
              />
            </div>
          )
        }
      </div>
    )
  }
}

export default class SurveyQuestions extends Component {
  constructor(props) {
    super(props)
    this.state = { questions: props.questions }
  }

  onAddQuestion = (e) => {
    e.preventDefault()
    const newQuestion = { guid: uuid.v4(), title: '', type: 'text', options: [] }
    this.setState((prevState) => ({ questions: [...prevState.questions, newQuestion] }))
  }

  onMoveDown = (index) => {
    this.setState((prevState) => {
      const newQuestions = [...prevState.questions]
      newQuestions[index + 1] = prevState.questions[index]
      newQuestions[index] = prevState.questions[index + 1]
      return { questions: newQuestions }
    })
  }

  onRemove = (index) => {
    this.setState((prevState) => ({
      questions: prevState.questions.filter((_, curIndex) => index !== curIndex)
    }))
  }

  render() {
    const questions = this.state.questions

    return (
      <div className="survey-questions">
        <div className="title-container">
          <h3 className="title">Perguntas</h3>
          <div className="action">
            <button
              className="btn -primary"
              onClick={this.onAddQuestion}
            >
              Adicionar pergunta
            </button>
          </div>
        </div>
        {
          questions.map((question, index) => (
            <SurveyQuestion
              key={question.guid}
              question={question}
              first={index == 0}
              last={index == questions.length - 1}
              onUpdate={(value) => {
                this.setState((prevState) => ({
                  questions: prevState.questions.map((currQuestion) => {
                    if (currQuestion.guid !== question.guid) return currQuestion
                    return { ...currQuestion, ...value }
                  })
                }))
              }}
              onMoveUp={() => this.onMoveDown(index - 1)}
              onMoveDown={() => this.onMoveDown(index)}
              onDelete={() => this.onRemove(index)}
            />
          ))
        }
      </div>
    )
  }
}
