import React, { PureComponent } from 'react'

export default class DatePickerInput extends PureComponent {
  render() {
    const { onClick, value } = this.props
    return (
      <button
        className="btn -primary -spaced -spaced-top -spaced-bottom -small"
        onClick={(e) => { e.preventDefault(); onClick(e) }}
      >
        {value}
      </button>
    )
  }
}
