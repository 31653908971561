import $ from 'jquery'
import 'jquery-mask-plugin'

export const ApplyPhoneMaskBehavior = (el) => {
  const behavior = (val) => val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009'
  el.mask(behavior, { onKeyPress: (val, _, field, options) => field.mask(behavior(val), options) })
}

$(() => {
  ApplyPhoneMaskBehavior($('[data-phone-mask]'))
})
