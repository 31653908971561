import React, { Component } from 'react'
import SlideDown from 'react-slidedown'
import { List, Form } from './calendar-event-observations'
import Pager from './Pager'
import If from './if'

export default class RequestObservations extends Component {
  state = { visible: true }

  _onToggle = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({ visible: !prevState.visible }))
  }

  render() {
    const { visible } = this.state
    const {
      calendarId,
      eventId,
      canManage
    } = this.props

    return (
      <div className="request-observations" style={{ padding: '0 16px 16px' }}>
        <h4 className="title">
          <span>Observações do Evento</span>
          <button onClick={this._onToggle} className="btn -primary -small -spaced -withrighticon">
            {visible ? 'Ocultar' : 'Mostrar'}

            <span className="icon">
              {visible ? '▲' : '▼'}
            </span>
          </button>
        </h4>

        <SlideDown>
          <If test={visible}>
            <Pager
              endpoint={`/calendars/${calendarId}/events/${eventId}/observations.json`}
              resource="event_observations"
              listComponent={List}
              listProps={{ canManage }}
              formComponent={Form}
              formProps={{ calendarId, eventId }}
            />
          </If>
        </SlideDown>
      </div>
    )
  }
}
