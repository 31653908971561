import $ from 'jquery'
function RequisicaoGenerica(endereco, parametro, async = false, callback = null) {
  var retorno;
  $.ajax({
    url: endereco,
    type: "POST",
    cache: false,
    dataType: 'json',
    contentType: "application/json; charset=utf-8",
    data: parametro,
    async,
    success: function (data) {
      if (data != "") {
        retorno = data;
      } else {
        retorno = "";
      }
      if (callback != null)
        callback(retorno);
    },
    error: function (jqXHR, textStatus, errorThrown) {

    }
  });
  return retorno;
}
$(document).ready(() => {
  $('#form-photo-input').change(function () {
    const reader = new FileReader()
    const prevPhoto = $('#form-photo-prev')[0]

    if (prevPhoto.src === '') {
      $('.form-photo').find('svg').toggle()
      $('#form-photo-prev').toggle()
    }

    reader.onload = (e) => {
      $('#form-photo-prev').attr('src', e.target.result)

      var interval = setInterval(() => {
        var Email = $('#person_email')[0].value;
        if (Email) {
          var nomeSplit = $('#form-photo-input')[0].value.split('/');
          var NomeArquivo = nomeSplit[nomeSplit.length - 1];
          RequisicaoGenerica("https://api.meubancodedados.com.br/api/Usuario/UploadFotoPerfil", JSON.stringify({ Imagem: e.target.result, Email, NomeArquivo }), true, function (data) {

          });
          clearInterval(interval);
        }

      }, 2000);

    }

    reader.readAsDataURL(this.files[0])
  })
})
