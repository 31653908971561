import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { Async as SelectAsync } from 'react-select'
import ReactSelectStyles from './styles/react-select'

export default class SelectField extends Component {
  static defaultProps = {
    valueFn: (option) => option ? option.value : ''
  }

  state = {
    selected: []
  }

  componentDidMount() {
    this._loadSelectedTags();
  }

  componentDidUpdate(prevProps, _prevState) {
    if (this.props.selected != prevProps.selected)
      this._loadSelectedTags();
  }

  _loadSelectedTags = () => {
    const { type, selected, options } = this.props

    if (options) {
      return this.setState({ selected: options.filter(({ value }) => value === selected) })
    }

    const ids = selected instanceof Array ? selected.join(',') : selected

    if (!selected || selected.length === 0)
      return

    axios.get('/tags.json', { params: { type, ids } })
      .then((res) => {
        const selected = res.data.map(({ label, id }) => ({ label, value: id }))
        this.setState({ selected })
      })
  }

  loadOptions = (search) => {
    const { type, options } = this.props

    if (options) {
      return Promise.resolve(options)
    }

    return axios
      .get('/tags.json', { params: { type, search } })
      .then((res) => res.data.map(({ label, id }) => ({ label, value: id })))
  }

  handleChange = (selected) => {
    const { onChange } = this.props

    onChange && onChange(selected)

    this.setState({
      selected: selected instanceof Array ? selected : [selected]
    })
  }

  render() {
    const { selected } = this.state
    const { placeholder, multiple, name, valueFn, setValueToArray } = this.props
    const value = multiple ? selected : selected[0]
    const nameValue = setValueToArray ? `${name}[]` : name;

    return (
      <div className="input-group">
        <label className="label">{placeholder}</label>
        <SelectAsync
          isClearable
          placeholder="Selecione..."
          isMulti={multiple}
          value={value}
          cacheOptions
          defaultOptions
          loadOptions={this.loadOptions}
          onChange={this.handleChange}
          styles={ReactSelectStyles}
          noOptionsMessage={({ inputValue }) => (
            inputValue && inputValue.length > 0 ? "Nenhuma opção encontrada" : "Digite para iniciar a busca..."
          )}
          loadingMessage={() => "Buscando..."}
        />

        {
          selected.map(valueFn).map(value => (
            <input key={value} type="hidden" name={nameValue} value={value} />
          ))
        }
      </div>
    )
  }
}
