import React, { useState } from 'react'
import Datetime from 'react-datetime'
import moment from 'moment'
import 'react-datetime/css/react-datetime.css'

export default ({ label, startDateName, endDateName, defaultStartDate, defaultEndDate }) => {
  const [startDate, setStartDate] = useState(moment(defaultStartDate))
  const [endDate, setEndDate] = useState(moment(defaultEndDate))
  const [endDateDirty, setEndDateDirty] = useState(false)

  const yesterday = moment().subtract(1, "day");
  const isValidStartDate = (current) => current.isAfter(yesterday);
  const isValidEndDate = (current) => current.isSameOrAfter(moment(startDate), 'day');

  const renderInput = (props, openCalendar, closeCalendar) => (
    <button
      className="tag -primary -large"
      onClick={(event) => {
        event.preventDefault()
        openCalendar(event)
      }}
    >
      {props.value}
    </button>
  )

  return (
    <div className="input-group">
      <label className="label">{label}</label>

      <div className="range-container">
        <input type="hidden" name={startDateName} value={moment(startDate).format()} />
        <Datetime
          locale="pt-BR"
          isValidDate={isValidStartDate}
          renderInput={renderInput}
          onChange={(value) => {
            setStartDate(value)
            let newEndDate = endDate
            if (!endDateDirty) newEndDate = moment(value).add(1, 'H')
            if (!endDate.isSameOrAfter(moment(value), 'day')) newEndDate = value
            setEndDate(newEndDate)
          }}
          value={startDate}
        />

        <span> até </span>

        <input type="hidden" name={endDateName} value={moment(endDate).format()} />
        <Datetime
          locale="pt-BR"
          isValidDate={isValidEndDate}
          renderInput={renderInput}
          onChange={(value) => {
            if (moment(value).isAfter(startDate)) {
              setEndDate(value)
              setEndDateDirty(true)
            }
          }}
          value={endDate}
        />
      </div>
    </div>
  )
}
