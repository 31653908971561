import React, { useMemo, useState } from 'react'
import RRule, {Weekday} from 'rrule'
import Datetime from 'react-datetime'
import If from './if'
import moment from 'moment'

export default function RecurrenceField({ fieldName, value }) {
  const [checked, setChecked] = useState(!!value)
  const [rruleStr, setRruleStr] = useState(() => {
    if (value) return value
    return new RRule({ freq: RRule.DAILY, interval: 1, wkst: RRule.SU }).toString()
  })

  const rrule = useMemo(() => RRule.fromString(rruleStr), [rruleStr])
  const interval = useMemo(() => parseInt(rrule.origOptions.interval, 10), [rrule])
  const endDate = useMemo(() => moment(rrule.origOptions.until || ''), [rrule])
  const [untilDateChecked, setUntilDateChecked] = useState(!!rrule.origOptions.until)

  const changeRRuleProp = (prop, value) => {
    let additionalProps = {}
    if (prop === 'freq' && value != RRule.WEEKLY) {
      additionalProps.byweekday = null
    }
    console.log({ ...rrule.origOptions, [prop]: value, ...additionalProps })
    const newRrule = new RRule({ ...rrule.origOptions, [prop]: value, ...additionalProps })
    setRruleStr(newRrule.toString())
    console.log({ a: newRrule.toString() , b: newRrule.origOptions.until })
  }

  const weekdays = Object.entries({
    [RRule.SU]: 'Domingo',
    [RRule.MO]: 'Segunda',
    [RRule.TU]: 'Terça',
    [RRule.WE]: 'Quarta',
    [RRule.TH]: 'Quinta',
    [RRule.FR]: 'Sexta',
    [RRule.SA]: 'Sábado'
  })

  return (
    <div style={{ padding: '8px 16px 8px'}}>
      <input type="hidden" name={fieldName} value={checked ? rruleStr : ''} />

      <label className="checkbox -flat">
        <span className="insidelabel">Repetir?</span>
        <input
          type="checkbox"
          checked={checked}
          onChange={(event) => setChecked(event.target.checked)}
        />
        <span className="checkmark" />
      </label>

      <If test={checked}>
        <div style={{ padding: '8px 0'}}>
          <span>Repetir a cada</span>
          <input
            type="number"
            value={interval}
            onChange={(ev) => changeRRuleProp('interval', ev.target.value)}
            style={{ margin: '0 8px', width: 50 }}
          />
          <select value={rrule.origOptions.freq} onChange={(ev) => changeRRuleProp('freq', ev.target.value)}>
            <option value={RRule.DAILY}>dia{interval > 1 ? 's' : ''}</option>
            <option value={RRule.WEEKLY}>semana{interval > 1 ? 's' : ''}</option>
            <option value={RRule.MONTHLY}>{interval > 1 ? 'meses' : 'mês'}</option>
            <option value={RRule.YEARLY}>ano{interval > 1 ? 's' : ''}</option>
          </select>
        </div>

        <If test={rrule.origOptions.freq == RRule.WEEKLY}>
          <span>Nos seguintes dias:</span>
          {weekdays.map(([key, label]) => (
            <label key={key} style={{ marginLeft: 8 }}>
              <input
                type="checkbox"
                style={{ marginRight: 4 }}
                checked={(rrule.origOptions.byweekday || []).map((wd) => wd.toString()).includes(key)}
                onChange={(ev) => {
                  let newValue

                  if (ev.target.checked) {
                    let weekdayVal = Weekday.fromStr(key)

                    if (!rrule.origOptions.byweekday) {
                      newValue = weekdayVal
                    } else {
                      newValue = [...rrule.origOptions.byweekday, weekdayVal]
                    }
                  } else {
                    newValue = (rrule.origOptions.byweekday || []).filter((el) => el.toString() !== key)
                  }

                  changeRRuleProp('byweekday', newValue)
                }}
              />
              <span>{label}</span>
            </label>
          ))}
        </If>

        <div>
          <label>
            <input
              type="checkbox"
              style={{ marginRight: 4 }}
              checked={untilDateChecked}
              onChange={(ev) => {
                setUntilDateChecked(ev.target.checked)
                if (ev.target.checked) {
                  changeRRuleProp('until', new Date())
                } else {
                  changeRRuleProp('until', null)
                }
              }}
            />
            <span>{untilDateChecked ? 'Termina em:' : 'Definir data limite'}</span>
          </label>
          <span>
            <If test={untilDateChecked}>
              <Datetime
                locale="pt-BR"
                renderInput={(props, openCalendar) => (
                  <button
                    className="tag -primary -large"
                    onClick={(event) => {
                      event.preventDefault()
                      openCalendar(event)
                    }}
                  >
                    {props.value}
                  </button>
                )}
                onChange={(value) => changeRRuleProp('until', value.toDate())}
                value={endDate}
              />
            </If>
          </span>
        </div>
      </If>
    </div>
  )
}
