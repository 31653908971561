import React, { Component, Fragment } from 'react'
import axios from 'axios'
import moment from 'moment'
import papaparse from 'papaparse'
import SweetAlert from 'sweetalert2'
import { transliterate } from 'transliteration'
import * as FileSaver from 'file-saver'
import { Importer, ImporterField } from './react-csv-importer';
import Modal from 'react-modal'
import NetworkSelect from './NetworkSelect'
import cpf from 'cpf'
import 'select2'
import 'select2/dist/css/select2.min.css'
import $ from 'jquery'

export default class ImportPeople extends Component {
  static TagCache = {}
  static OptionCache = {}
  static TypeLabels = {
    Gender: 'Gênero',
    Bond: 'Vínculo',
    InfluencerType: 'Tipo de Influenciador',
    Schooling: 'Escolaridade',
    Profession: 'Profissão',
    Segment: 'Segmento',
    City: 'Cidade',
    Neighborhood: 'Bairro',
    Religion: 'Religião',
    State: 'Estado'
  }

  constructor(props) {
    super(props)
    this.state = {
      networkSelection: {
        open: false,
        resolve: Promise.resolve(),
      },
      selectedNetwork: props.currentNetwork,
      override: ''
    }
  }

  loadTagOptions = (type) => (
    axios
      .get('/tags.json', { params: { type } })
      .then((res) => res.data.map(({ label, id }) => ({ label, value: id })))
  )

  loadCachedTag = async (type) => {
    if (!ImportPeople.TagCache[type])
      ImportPeople.TagCache[type] = await this.loadTagOptions(type)

    return ImportPeople.TagCache[type]
  }

  splitNameAndSurname = (name, surname) => {
    if (name && surname) return { name, surname }

    let [firstName, ...rest] = name.split(' ')
    return { name: firstName, surname: rest.join(' ') }
  }

  yearNormalizer = (_, day, month, year) => [day, month, `19${year}`.substring(year.length + 2 - 4)].join('/')

  sanitizeBirthDate = (birthDate) => {
    const dateWithBars = birthDate.replace(/[\.-]/g, '/').replace(/^(\d{2})(\d{2})(\d{2}|\d{4})$/, '$1/$2/$3')
    const dateWith4DigitYear = dateWithBars.replace(/^(\d{1,2})\/(\d{1,2})\/(\d{2}|\d{4})$/, this.yearNormalizer)
    return dateWith4DigitYear
  }

  parseBirthDate = (birthDate) => moment(this.sanitizeBirthDate(birthDate), 'D/M/YYYY').format()

  sanitizeNumber = (number) => number.replace(/\D/, '')

  sanitizePhoneNumber = (number) => {
    const sanitizedNumber = this.sanitizeNumber(number)

    switch (sanitizedNumber.length) {
      case 8:
        // 9123-4567 => 61 99123-4567
        // 3003-4004 => 61 3003-4004
        return (['9', '8'].includes(sanitizedNumber.substr(0, 1)) ? `619${sanitizedNumber}` : `61${sanitizedNumber}`)

      case 9:
        // 99123-4567 => 61 99123-4567
        return `61${sanitizedNumber}`

      case 10:
        // 61 9123-4567 => 61 99123-4567
        if (['9', '8'].includes(sanitizedNumber.substr(2, 1))) {
          return `${sanitizedNumber.substr(0, 2)}9${sanitizedNumber.substr(2)}`
        }

      default:
        return sanitizedNumber
    }
  }

  sanitizeTagLabel = (string) => transliterate(string).toLowerCase().trim()

  selectTagFor = async (type, value) => {
    value = value.trim()
    const comparableValue = this.sanitizeTagLabel(value)
    // Consult option cache first
    ImportPeople.OptionCache[type] = ImportPeople.OptionCache[type] || {}
    const savedOptions = ImportPeople.OptionCache[type]

    if (Object.keys(savedOptions).includes(comparableValue))
      return savedOptions[comparableValue]

    // Load tags and check matches
    const options = await this.loadCachedTag(type);
    for (const option of options) {
      if (this.sanitizeTagLabel(option.label) == comparableValue) {
        ImportPeople.OptionCache[type][comparableValue] = option.value;
        return option.value;
      }
    }

    // Asks what to do
    const createTagOption = `Criar uma tag com o valor "${value}"`
    const ignoreOption = `Ignorar esta tag`
    const typeLabel = ImportPeople.TypeLabels[type]
    const inputOptions = [
      createTagOption,
      ignoreOption,
      ...options.map(({ label }) => `Substitua por "${label}"`)
    ]

    // const { value: selectedOption } = await SweetAlert.fire({
    //   title: `Identificamos um valor desconhecido na importação de ${typeLabel}`,
    //   text: `Para o valor "${value}", o que você deseja fazer?`,
    //   input: 'select',
    //   inputOptions,
    //   showCancelButton: false,
    //   allowOutsideClick: false,
    //   inputAttributes: { id: 'autoComplete' },
    //   onOpen: () => { $(SweetAlert.getInput()).select2() }
    // })
    try {
      const response = await axios.post('/tags.json', { type, labels: [value] })
      const id = response.data[value]
      delete ImportPeople.TagCache[type]
      ImportPeople.OptionCache[type][comparableValue] = id
      return id;
    } catch (e) {
      console.error(e)
      return null;
    }
    switch (selectedOption) {
      case '0':
        try {
          const response = await axios.post('/tags.json', { type, labels: [value] })
          const id = response.data[value]
          delete ImportPeople.TagCache[type]
          ImportPeople.OptionCache[type][comparableValue] = id
          return id;
        } catch (e) {
          console.error(e)
          return null;
        }
      case '1':
        ImportPeople.OptionCache[type][comparableValue] = null;
        return null
      default:
        const option = options[selectedOption - 2]
        ImportPeople.OptionCache[type][comparableValue] = option.value;
        return option.value;
    }
  }

  parseRow = async (row) => {
    const requestParams = {
      ...this.splitNameAndSurname(row.name, row.surname),
    }

    if (row.birth_date)
      requestParams.birth_date = this.parseBirthDate(row.birth_date)

    if (row.cep)
      requestParams.cep = this.sanitizeNumber(row.cep)

    if (row.phone1)
      requestParams.phone1 = this.sanitizePhoneNumber(row.phone1)

    if (row.phone2)
      requestParams.phone2 = this.sanitizePhoneNumber(row.phone2)

    if (row.phone3)
      requestParams.phone3 = this.sanitizePhoneNumber(row.phone3)

    if (row.street_name)
      requestParams.street_name = row.street_name

    if (row.address_number)
      requestParams.address_number = row.address_number

    if (row.address_complement)
      requestParams.address_complement = row.address_complement

    if (row.cpf)
      requestParams.cpf = this.sanitizeNumber(row.cpf)
    // else {
    //   requestParams.cpf = (621355968e9 + (new Date()).getTime() * 1e4).toString().substr(0, 11);
    // }

    if (row.email)
      requestParams.email = row.email

    if (row.electoral_section)
      requestParams.electoral_section = row.electoral_section

    if (row.electoral_zone)
      requestParams.electoral_zone = row.electoral_zone

    // FAIL FAST
    const invalidCpf = requestParams.cpf && !cpf.isValid(requestParams.cpf)
    const invalidCep = requestParams.cep && requestParams.cep.length !== 8
    const blankSurname = !requestParams.surname
    if (invalidCpf || invalidCep || blankSurname) {
      return requestParams
    }

    let personAlreadyExist = false
    let response = await axios.get('/people/exists.json', { params: { cpf: requestParams.cpf, email: requestParams.email } })
    personAlreadyExist = response.data.exists

    if (personAlreadyExist) return requestParams

    const tags = Object.entries({
      gender: 'Gender',
      bond: 'Bond',
      document_type: 'DocumentType',
      influencer_type: 'InfluencerType',
      schooling: 'Schooling',
      profession: 'Profession',
      segment1: 'Segment',
      segment2: 'Segment',
      segment3: 'Segment',
      attendance: 'Attendance',
      employment_role: 'EmploymentRole',
      city: 'City',
      neighborhood: 'Neighborhood',
      religion: 'Religion',
      state: 'State'
    })

    for (let [key, tagType] of tags) {
      let tagsIds

      if (!row[key])
        continue;

      if (['influencer_type', 'segment1', 'segment2', 'segment3'].includes(key)) {
        tagsIds = []
        for (const opt of row[key].split(',')) {
          const id = await this.selectTagFor(tagType, opt)
          tagsIds.push(id)
        }
      } else {
        if (tagType == 'City' && row[key].indexOf('RA ') >= 0 && row[key].indexOf(':') >= 0) {
          row[key] = row[key].split(':')[1].trim();
        }
        tagsIds = await this.selectTagFor(tagType, row[key]);
      }

      if (tagsIds)
        requestParams[key] = tagsIds;
    }

    return requestParams;
  }

  determinePhoneType = (phone) => {
    if (!phone) return;

    const cellphoneIndicators = ['9', '8']
    const startsAsCellphone = (digit) => cellphoneIndicators.includes(digit)
    const backendType = (digit) => startsAsCellphone(digit) ? 'cellphone' : 'landline'

    switch (phone.length) {
      case 8:
      case 9: return backendType(phone.substr(0, 1))
      case 10:
      case 11: return backendType(phone.substr(2, 1))
    }
  }

  translatePhones = (row) => {
    const phones = {}

    for (let index = 1; index <= 3; index++) {
      const attrName = `phone${index}`
      const phone = row[attrName]
      if (phone)
        phones[index - 1] = { number: phone, type: this.determinePhoneType(phone) }
    }

    return phones
  }

  translateRow = (row) => {
    return {
      person: {
        name: row.name,
        surname: row.surname,
        email: row.email,
        cpf: row.cpf,
        birth_date: row.birth_date,
        cep: row.cep,
        street_name: row.street_name,
        address_number: row.address_number,
        role: 'none',
        gender_id: row.gender,
        schooling_id: row.schooling,
        profession_id: row.profession,
        bond_id: row.bond,
        religion_id: row.religion,
        is_influencer: !!row.influencer_type,
        city_id: row.city,
        neighborhood_id: row.neighborhood,
        state_id: row.state,
        segment_ids: [row.segment1, row.segment2, row.segment3].flat().filter((el) => !!el),
        influencer_type_ids: row.influencer_type,
        phones_attributes: this.translatePhones(row),
        network_id: this.state.selectedNetwork,
        electoral_zone: row.electoral_zone,
        electoral_section: row.electoral_section
      }
    }
  }

  processChunk = async (rows) => {
    for (const row of rows) {
      try {
        const parsed = await this.parseRow(row)
        const translated = this.translateRow(parsed)
        let personAlreadyExist = false
        axios.get('/people/exists.json', { params: { cpf: parsed.cpf, email: parsed.email } }).then(async (response) => {
          personAlreadyExist = response.data.exists
          if (personAlreadyExist == false) {
            try {
              await axios.post('/people.json', translated)
            } catch (e) {
              let errors;

              if (e.response && e.response.data) {
                errors = e.response.data.errors.join(', ')
              } else {
                console.error(e)
                errors = 'Não foi possível se comunicar com o servidor'
              }
              this.importErrors.push({ ...row, errors })
            }
          }
          else {
            await axios.post('https://api.meubancodedados.com.br/api/Usuario/Importar?opcaoExistentes=' + this.state.override, translated)
          }
        })
      } catch (e) {
        console.log(e);
      }
    }
  }

  onStartImport = ({ file, fields, columns, skipHeaders }) => {
    return new Promise((resolve) => {
      this.importErrors = []
      this.setState({ networkSelection: { open: true, resolve }, override: 'sobrescrever' })
    })
  }

  onConfirmNetwork = () => {
    const el = $('input[name="override"]:checked');
    const override3 = $('input[name="override"]:checked').val();

    // const override2 = $('input[name="override"]:checked').value;
    this.setState({ networkSelection: { open: false, resolve: Promise.resolve() }, override: override3 })
    this.state.networkSelection.resolve()
  }

  onCompleteImport = async () => {
    if (this.importErrors.length == 0)
      return

    const errorsLabel = `${this.importErrors.length} linha${this.importErrors.length == 1 ? '' : 's'}`
    const { value } = await SweetAlert.fire({
      title: 'Atenção!',
      text: `Identificamos erros de importação em ${errorsLabel}. Clique abaixo para baixar um arquivo contendo os dados e os respectivos erros`,
      type: 'warning',
      allowOutsideClick: false,
      confirmButtonText: 'Salvar',
      cancelButtonText: 'Ignorar',
      showCancelButton: true
    })

    if (value) {
      try {
        const data = papaparse.unparse(this.importErrors, { quotes: true, header: true, delimiter: ';' })
        const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
        FileSaver.saveAs(blob, 'resultado-importacao.csv')
      } catch (e) {
        console.error(e)
      }
    }
  }

  render() {
    return (
      <Fragment>
        <Importer
          restartable
          preview={false}
          chunkSize={1000}
          assumeNoHeaders={false}
          onStart={this.onStartImport}
          processChunk={this.processChunk}
          onComplete={this.onCompleteImport}
        >
          <ImporterField name="name" label="Nome" />
          <ImporterField name="surname" label="Sobrenome" optional />
          <ImporterField name="cpf" label="CPF" optional />
          <ImporterField name="email" label="Email" optional />
          <ImporterField name="birth_date" label="Data de Nascimento" optional />
          <ImporterField name="cep" label="CEP" optional />
          <ImporterField name="city" label="Cidade" optional />
          <ImporterField name="neighborhood" label="Bairro" optional />
          <ImporterField name="state" label="Estado" optional />
          <ImporterField name="street_name" label="Endereço" optional />
          <ImporterField name="address_number" label="Número (Endereço)" optional />
          <ImporterField name="address_complement" label="Complemento (Endereço)" optional />
          <ImporterField name="phone1" label="Telefone 1" optional />
          <ImporterField name="phone2" label="Telefone 2" optional />
          <ImporterField name="phone3" label="Telefone 3" optional />
          <ImporterField name="gender" label="Gênero" optional />
          <ImporterField name="schooling" label="Escolaridade" optional />
          <ImporterField name="profession" label="Profissão" optional />
          <ImporterField name="bond" label="Vínculo" optional />
          <ImporterField name="religion" label="Religião" optional />
          <ImporterField name="influencer_type" label="Tipo de Influenciador" optional />
          <ImporterField name="segment1" label="Segmento 1" optional />
          <ImporterField name="segment2" label="Segmento 2" optional />
          <ImporterField name="segment3" label="Segmento 3" optional />
          <ImporterField name="electoral_zone" label="Zona Eleitoral" optional />
          <ImporterField name="electoral_section" label="Seção Eleitoral" optional />
        </Importer>
        <Modal
          isOpen={this.state.networkSelection.open}
          onRequestClose={this.onCloseNetworkSelection}
          contentLabel="Seleção de Rede de Usuários"
          className="curriculum-modal -reverted"
        >
          <div className="input-group -centered -horizontal -padded influencer">
            <span className="question">O que deseja fazer com registros duplicados?</span>
            <div className="radiotoggle">
              <input type="radio" name="override" id="is_influencer_1" value="sobrescrever" className="input" />
              <label className="tag -negative" htmlFor="is_influencer_1">Sobrescrever todos os campos</label>
            </div>

            <div className="radiotoggle">
              <input type="radio" name="override" id="is_influencer_2" value="sobrescrever-vazio" className="input" />
              <label className="tag -positive" htmlFor="is_influencer_2">Sobrescrever campos vazios</label>
            </div>
            <div className="radiotoggle">
              <input type="radio" name="override" id="is_influencer_3" value="ignorar" className="input" />
              <label className="tag -positive" htmlFor="is_influencer_3">Ignorar</label>
            </div>
          </div>
          <NetworkSelect
            placeholder="Selecione a rede de usuários que você deseja importar os cadastros:"
            onChange={(selection) => this.setState({ selectedNetwork: selection.value })}
            selected={this.props.currentNetwork}
          />

          <div style={{ textAlign: 'right' }}>
            <button
              disabled={!this.state.selectedNetwork}
              className={`btn -small -spaced-top ${this.state.selectedNetwork ? '-primary' : ''}`}
              onClick={this.onConfirmNetwork}
            >
              Iniciar a importação
            </button>
          </div>
        </Modal>
      </Fragment>
    )
  }
}
