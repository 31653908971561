import React, { Component, Fragment } from 'react'
import SelectField from './SelectField'
import If from './if'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

export default class ProfileFields extends Component {
  state = {
    selectedRole: null
  }

  onRoleChange = (role) => this.setState({ selectedRole: role.value })

  _hasErrors = (field) => Object.keys(this.props.errors[field] || {}).length > 0

  _inputModifier = (field) => {
    const { formSent } = this.props
    const inputModifier = this._hasErrors(field) ? '-error' : '-success'
    return formSent ? inputModifier : ''
  }

  renderPasswordFields = () => {
    const { password } = this.props
    const { passwordName, confirmationName } = password

    return (
      <Fragment>
        <div className={`input-group ${this._inputModifier('password')}`}>
          <label className="label">Senha</label>
          <input className="input" type="password" name={passwordName} />
        </div>
        <div className={`input-group ${this._inputModifier('password_confirmation')}`}>
          <label className="label">Repetir Senha</label>
          <input className="input" type="password" name={confirmationName} />
        </div>
      </Fragment>
    )
  }

  render() {
    const { role } = this.props
    const selectedRole = this.state.selectedRole || role.selected
    const showPasswordFields = (selectedRole && selectedRole != 'none')

    return (
      <Fragment>
        <div className={`input-group ${this._inputModifier('role')}`}>
          <SelectField
            selected={role.selected}
            name={role.inputName}
            options={role.options}
            placeholder="Tipo de Conta"
            onChange={this.onRoleChange}
          />
        </div>
        <If test={!selectedRole}>
          <input type="hidden" name={role.inputName} value="" />
        </If>
        <SlideDown>
          { showPasswordFields && this.renderPasswordFields() }
        </SlideDown>
      </Fragment>
    )
  }
}
