import React, { Component } from 'react'
import SlideDown from 'react-slidedown'
import { List, Form } from './person-observations'
import Pager from './Pager'
import If from './if'

export default class PersonObservations extends Component {
  state = { visible: true }

  _onToggle = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({ visible: !prevState.visible }))
  }

  render() {
    const { visible } = this.state
    const { personId, canManage } = this.props

    return (
      <div className="person-observations">
        <h4>
          <span>Observações sobre o Usuário</span>
          <button onClick={this._onToggle} className="btn -primary -small -spaced -withrighticon">
            {visible ? 'Ocultar' : 'Mostrar'}

            <span className="icon">
              {visible ? '▲' : '▼'}
            </span>
          </button>
        </h4>

        <div className="row">
          <SlideDown>
            <If test={visible}>
              <Pager
                endpoint={`/people/${personId}/observations.json`}
                resource="person_observations"
                listComponent={List}
                listProps={{ canManage }}
                formComponent={Form}
                formProps={{ personId }}
              />
            </If>
          </SlideDown>
        </div>
      </div>
    )
  }
}
