import React, { useEffect, useRef, useState } from 'react'
import SelectField from './SelectField'
import QRCode from 'react-qr-code'
import QrCodeLink from 'qrcode'

export default function RegisterLink({ baseLink,currentUser }) {
  const txtArea = useRef()
  const [segments, setSegments] = useState([])
  const [link, setLink] = useState(baseLink)
  const [qrCodeLink, setQrCodeLink] = useState('')

  function handleGenerateImage(baseLink){
    QrCodeLink.toDataURL(baseLink,{
      width:600,
      margin:3
    }, function(err, url){
      setQrCodeLink(url)
    })
  }

  useEffect(() => {
    if (!segments.length) {
      setLink(baseLink)
      handleGenerateImage(baseLink)
    }

    const suffix = segments.length ? `&s=${segments.join(',')}` : ''
    setLink(`${baseLink}${suffix}`)
    handleGenerateImage(`${baseLink}${suffix}`)
  }, [segments])

  const onChangeSegments = (selected) => {
    setSegments(selected.map(({ value }) => value))
  }

  const onCopy = () => {
    let msg = 'Link copiado'
    window.disableClipboardEffect = true
    txtArea.current.select()

    try {
      if (!document.execCommand('copy')) msg = 'Problemas na cópia do link'
    } catch (err) {
      msg = 'Opa! Não conseguimos copiar o texto. É possivel que o seu navegador não tenha suporte, tente usar Crtl+C.'
    }

    alert(msg)
  }

  return (
    <div className="card">
      {(currentUser === 'admin' || currentUser === 'superadmin') && (
        <SelectField
        type="Segment"
        multiple="true"
        placeholder="Segmentos para esse cadastro"
        onChange={onChangeSegments}
      />
      )}

      <div className="input-group -horizontal">
        <div className="input-group -stretched">
          <textarea ref={txtArea} data-copy="allow" className="input" value={link} readOnly></textarea>
        </div>
        <div className="input-group -centered">
          <button className="btn -primary -huge" onClick={onCopy}>Copiar Link de Cadastro</button>
        </div>
      </div>
        
      <div className='input-group -horizontal'>
        <QRCode value={link} className='mt-1'/>
        <div className='actions'>
        <a className="btn -primary -small" href={qrCodeLink} download={`qrcodeLinkDeCadastro.png`}  style={{ margin: '5rem 2rem 2rem 2rem' }}>Baixar QRCode</a>
        </div>
      </div>
    </div>
  )
}
