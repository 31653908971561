import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { Async as SelectAsync } from 'react-select'
import ReactSelectStyles from './styles/react-select'

export default class UserSelect extends Component {
  state = {
    selected: []
  }

  componentDidMount() {
    this._loadSelectedUsers();
  }

  componentDidUpdate(prevProps, _prevState) {
    if (this.props.selected != prevProps.selected)
      this._loadSelectedUsers();
  }

  _personToOption = ({ id, fullname, email }) => ({
    value: id,
    label: `${fullname} - ${email}`,
  })

  _optionsFromServerResponse = ({ data }) => data.map(this._personToOption)

  _loadSelectedUsers = () => {
    const { selected, force } = this.props

    const ids = selected instanceof Array ? selected.join(',') : selected

    if (!selected || selected.length === 0)
      return

    axios.get('/people.json', { params: { ids, force } }).then((res) => (
      this.setState({ selected: this._optionsFromServerResponse(res) })
    ))
  }

  loadOptions = (search) => {
    const { assignableOnly, canOwnNetwork } = this.props
    const params = { search }

    if (assignableOnly)
      params.assignable = '1'

    if (canOwnNetwork)
      params.can_own_network = '1'

    return axios
      .get('/people.json', { params })
      .then((res) => {
        if (!search && res.data.length <= 1) {
          return []
        } else {
          return this._optionsFromServerResponse(res)
        }
      })
  }

  handleChange = (selected) => {
    const { onChange } = this.props
    const selection = selected || []

    onChange && onChange(selection)

    this.setState({
      selected: selection instanceof Array ? selection : [selection]
    })
  }

  render() {
    const { selected } = this.state
    const { placeholder, multiple, name, isClearable } = this.props
    const value = multiple ? selected : selected[0]

    return (
      <div className="input-group">
        <label className="label">{placeholder}</label>
        <SelectAsync
          placeholder="Selecione..."
          isMulti={multiple}
          isClearable={isClearable}
          value={value}
          cacheOptions
          defaultOptions
          loadOptions={this.loadOptions}
          onChange={this.handleChange}
          styles={ReactSelectStyles}
          noOptionsMessage={({ inputValue }) => (
            inputValue && inputValue.length > 0 ? "Nenhuma opção encontrada" : "Digite para iniciar a busca..."
          )}
          loadingMessage={() => "Buscando..."}
        />

        {
          selected.map(({ value }) => (
            <input key={value} type="hidden" name={name} value={value} />
          ))
        }
      </div>
    )
  }
}
