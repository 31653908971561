import React, { Component } from 'react'
import { Creatable as SelectCreatable } from 'react-select'
import ReactSelectStyles from './styles/react-select'
import IcTags from '../images/ic-tags'
import axios from 'axios'
import SweetAlert from 'sweetalert2'

export default class TagEdit extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      label: props.tag.label
    }
  }

  onSubmit = () => {
    const { label } = this.state
    const { id, type } = this.props.tag

    axios.put(`/tags/${id}.json`, { label })
      .then(_ => window.location.href = `/tags/${type}`)
      .catch(_ => SweetAlert('Oops!', 'Ocorreu um erro para salvar a tag. Tente novamente mais tarde.'))
  }

  render() {
    const { label } = this.state

    return (
      <div className="tags-form">
        <div className="card -withoutafterpad">
          <div className="input-group">
            <label className="label">Editar Tag</label>
            <input
              className="input"
              type="text"
              placeholder="Nome da Tag"
              value={label}
              onChange={(e) => this.setState({label: e.currentTarget.value})}
              autoFocus
            />
          </div>
        </div>

        <button className="btn -primary -withrighticon" onClick={this.onSubmit}>
          <span className="icon"><IcTags/></span>
          <span>Salvar tag</span>
        </button>
      </div>
    )
  }
}
