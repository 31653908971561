import React, { useState, useMemo, useEffect } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import Popover from 'react-popover'
//import { streamArray } from 'stream-json/streamers/StreamArray'
import axios from 'axios'
import ProfilePhoto from './ProfilePhoto'
import If from './if'
import IcEdit from '../images/ic-edit'
import IcDelete from '../images/ic-delete'
import { ThreeCircles } from 'react-loader-spinner'
import 'moment/locale/pt-br'
import 'react-big-calendar/lib/sass/styles.scss'
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

const localizer = momentLocalizer(moment)

const getDefaultCalendarRange = () => {
  const today = new Date()

  let tempDate = new Date(today.getFullYear(), today.getMonth(), 1)
  let timestamp = +tempDate
  while (new Date(timestamp).getDay() != 0) timestamp = timestamp - 86400000
  const start = new Date(timestamp)

  tempDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  timestamp = +tempDate
  while (new Date(timestamp).getDay() != 6) timestamp = timestamp + 86400000
  const end = new Date(timestamp + 86400000 - 1)

  return { start, end }
}

const Spinner = ({ spinning, children }) => {
  return (
    <div className={`loader${spinning ? ' -visible' : ''}`}>
      <div className="spinner">
        <ThreeCircles
          color="blue"
          height={110}
          width={110}
          ariaLabel="three-circles-rotating"
        />
      </div>
      {children}
    </div>
  )
}

export default ({ calendars, calendarId, sentryDsn }) => {
  const [events, setEvents] = useState([])
  const [view, setView] = useState('month')
  const [range, setRange] = useState(getDefaultCalendarRange())
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    Sentry.init({
      dsn: sentryDsn,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    })

    Sentry.setContext('calendar', calendarId)
  })

  const onChangeRange = (newRange, newView) => {
    const currentView = newView || view
    let currentRange

    switch(currentView) {
      case 'month':
        currentRange = newRange
        break
      case 'week':
      case 'day':
        const sortedRange = newRange.sort((a,b) => a.getDate() < b.getDate() ? -1 : 1)
        currentRange = { start: sortedRange[0], end: new Date(sortedRange[sortedRange.length - 1].getTime() + 86400000 - 1) }
    }

    setView(currentView)
    setRange(currentRange)

    console.log({ currentView, currentRange })
  }

  useEffect(() => {
    setLoading(true)
    const params = { start: range.start.toISOString(), end: range.end.toISOString(), calendarId }
    axios
      .get('/calendars/list.json', { params })
      .then((response) => {
        setEvents(response.data)
        setLoading(false)
      })
      .catch((err) => Sentry.captureException(err))

    /*
    const encodeGetParams = p => Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
    console.log("FETCH EVENTS FOR ", range)
    const pipeline = streamArray()
    pipeline.on('data', ({ value }) => setEvents((oldEvents) => [...oldEvents, value]))
    pipeline.on('end', () => setLoading(false))

    fetch(`/calendars/list.json?${params}`).then(async(response) => {
      const reader = response.body.getReader()
      console.log("GOT READABLE STREAM")
      while (true) {
        console.log("READING")
        let { value, done } = await reader.read()
        console.log("READ")
        if (done) break;
        console.log("WRITING", value)
        pipeline.write(value)
      }
    })
    */
  }, [range.start, range.end])

  const groupMap = useMemo(() => (
    Object.fromEntries(calendars.map((calendar) => [calendar.id, calendar]))
  ), [calendars])

  const [popoverState, setPopoverState] = useState({})

  const items = useMemo(() => (
    events.map((event) => ({
      ...event,
      start: moment(event.start_time),
      startDate: moment(event.start_time).toDate(),
      end: moment(event.end_time),
      endDate: moment(event.end_time).toDate(),
      canMove: false,
      itemProps: {
        style: {
          background: groupMap[event.group].background,
          color: groupMap[event.group].foreground
        }
      }
    }))
  ), [events, popoverState, groupMap])

  const profile = (person) => (
    <div className="profile">
      <div className="photo">
        <ProfilePhoto inline photoable={person} />
      </div>
      <div className="info">
        <span className="name">{person ? person.fullname : ''}</span>
      </div>
    </div>
  )

  const deleteConfirmation = (event) => {
    if (!event.recurrence) {
      return "Tem certeza que deseja deletar este evento? Essa ação é irreversível."
    }

    return 'Esse evento é recorrente. Deletá-lo irá remover todas as ocorrrências. ' +
           "\n\n" +
           'Caso você deseje apenas encerrá-lo, sem remover as ocorrências anteriores, ' +
           'atualize o evento definindo uma data de término na área da recorrência. ' +
           "\n\n" +
           'Deseja deletar este evento? Essa ação é irreversível.'
  }

  const popoverBody = (event) => (
    <div className="popover -timeline">
      <div className="content">
        <div>
          <div className="header">
            <h5>{event.title}</h5>
            <If test={event.start.isSame(event.end, 'day')}>
              <strong>
                <span>{event.start.format('DD/MM/YY')}, </span>
                <span>de {event.start.format('HH:mm')} </span>
                <span>às {event.end.format('HH:mm')}</span>
              </strong>
            </If>
            <If test={!event.start.isSame(event.end, 'day')}>
              <strong>
                <span>De {event.start.format('DD/MM/YY HH:mm')} até </span>
                <span>{event.end.format('DD/MM/YY HH:mm')}</span>
              </strong>
            </If>
            <p>{event.description}</p>
          </div>

          <div className="section">
            <strong>Local:</strong>
            <span>{event.address} ({event.guest_estimate} convidados estimados)</span>
          </div>

          {
            event.conference_url && (
              <div className="section">
                <strong>Link da Conferência:</strong>
                <span><a href={event.conference_url} target="_blank">{event.conference_url}</a></span>
              </div>
            )
          }

          <If test={event.responsible.name || event.responsible.phone}>
            <div className="section">
              <strong>Responsável:</strong>
              {event.responsible.name || '?'}{event.responsible.phone ? `: ${event.responsible.phone}` : ''}
            </div>
          </If>

          <If test={event.host.name || event.host.phone}>
            <div className="section">
              <strong>Anfitrião:</strong>
              {event.host.name || '?'}{event.host.phone ? `: ${event.host.phone}` : ''}
            </div>
          </If>

          <div className="section">
            <strong>Criado por:</strong>
            {profile(event.created_by)}
          </div>

          <If test={event.forerunner}>
            <div className="section">
              <strong>Precursor:</strong>
              {profile(event.forerunner)}
            </div>
          </If>

          <If test={event.representative}>
            <div className="section">
              <strong>Representante:</strong>
              {profile(event.representative)}
            </div>
          </If>

          <If test={event.guests.length > 0}>
            <div className="section">
              <strong>Convidados:</strong>
              {
                event.guests.map((guest) => (
                  <div className="profile" key={guest.id}>
                    <div className="photo">
                      <ProfilePhoto inline photoable={guest} />
                    </div>
                    <div className="info">
                      <p className="name">{guest.fullname}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </If>

          <div className="actions">
            <If test={event.editUrl}>
              <a href={event.editUrl}>
                <IcEdit />
              </a>
            </If>
            <If test={event.deleteUrl}>
              <a
                href={event.deleteUrl}
                data-method="DELETE"
                data-confirm={deleteConfirmation(event)}
              >
                <IcDelete />
              </a>
            </If>
          </div>
        </div>

        <If test={event.observations.length > 0}>
          <div className="observations">
            <h4>Observações:</h4>
            {
              (event.observations || []).map((observation) => (
                <div key={observation.id} className="observation">
                  <strong>{observation.author.fullname} ({observation.created_at})</strong>
                  <div>
                    {observation.content}
                  </div>
                </div>
              ))
            }
          </div>
        </If>
      </div>
    </div>
  )

  const eventsWithPopover = []

  return (
    <Spinner spinning={loading}>
      <Calendar
        popup
        style={{ height: '600px' }}
        localizer={localizer}
        events={items}
        views={['month', 'week', 'day']}
        defaultView="month"
        startAccessor="startDate"
        endAccessor="endDate"
        eventPropGetter={(event) => event.itemProps}
        onRangeChange={onChangeRange}
        messages={{
          date: 'Data',
          time: 'Hora',
          event: 'Evento',
          allDay: 'Tida todo',
          week: 'Semana',
          day: 'Dia',
          month: 'Mês',
          previous: '⬅️',
          next: '➡️',
          yesterday: 'Ontem',
          tomorrow: 'Amanhã',
          today: 'Hoje',
          showMore: total => `+${total} eventos`,
        }}
        onSelectEvent={(event) => setPopoverState((old) => ({ ...old, [event.id]: !popoverState[event.id] }))}
        components={{
          event: ({ event }) => {
            let Wrapper = 'div'
            let wrapperProps = {}

            if (!eventsWithPopover.includes(event.id)) {
              eventsWithPopover.push(event.id)
              const isOpen = popoverState[event.id]
              Wrapper = Popover
              wrapperProps = { isOpen, onOuterAction: () => setPopoverState((old) => ({ ...old, [event.id]: false })), body: popoverBody(event) }
            }

            return (
              <Wrapper {...wrapperProps}>
                <div>{event.title}</div>
              </Wrapper>
            )
          }
        }}
      />
    </Spinner>
  )
}
