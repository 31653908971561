import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import Dropzone from 'react-dropzone'
import SelectField from './SelectField'
import FormData from 'form-data'
import axios from 'axios'
import SweetAlert from 'sweetalert2'
import $ from 'jquery'

Modal.setAppElement('.profile')

export default class CurriculumModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      acceptedFiles: [],
      hasDisability: null,
      foundJob: null,
      who_indicate: '',
      priority: 3,
      observation: '',
      employmentRoles: [],
      initialEmploymentRolesIds: [],
      curriculum_id: null
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.buildFormData = this.buildFormData.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.requestAction = this.requestAction.bind(this)

    this.initialize(props)
  }

  initialize() {
    if (this.props.has_curriculum) {
      axios
        .get(`/people/${this.props.person_id}/curriculum`)
        .then((res) => {
          const data = res['data']
          const employmentRolesIds = data.employment_roles.map((er) => er['value'])

          this.setState({
            initialEmploymentRolesIds: employmentRolesIds,
            employmentRoles: data.employment_roles,
            hasDisability: data.has_disability ? 1 : 0,
            foundJob: data.found_job ? 1 : 0,
            priority: data.priority,
            who_indicate: data.who_indicate,
            observation: data.observation,
            curriculum_id: data.curriculum_id
          })
        })
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles[0].size > 5000000) {
      SweetAlert('Oops!', 'O arquivo não pode ser maior que 5 MB.')
    } else if (!this.validateFileFormat(acceptedFiles)) {
      SweetAlert('Oops!', 'O arquivo precisa ser um PDF, DOC ou DOCX.')
    } else {
      this.setState({ acceptedFiles })
    }
  }

  validateFileFormat(acceptedFiles) {
    return (acceptedFiles[0].name.includes(".pdf") || acceptedFiles[0].name.includes(".doc") || acceptedFiles[0].name.includes(".docx"))
  }

  buildFormData() {
    let formData = new FormData()
    if (this.state.acceptedFiles[0])
      formData.append('curriculum_file', this.state.acceptedFiles[0], this.state.acceptedFiles[0].name)
    formData.append('who_indicate', this.state.who_indicate)
    formData.append('has_disability', this.state.hasDisability === 1)
    formData.append('found_job', this.state.foundJob === 1)
    formData.append('priority', this.state.priority)
    formData.append('observation', this.state.observation)
    formData.append('employment_roles', JSON.stringify(this.state.employmentRoles))

    return formData
  }

  submitForm() {
    this.requestAction()
      .then(_ => window.location.reload())
      .catch(_ => SweetAlert('Oops!', 'Ocorreu um erro para salvar o currículo. Tente novamente mais tarde.'))
  }

  requestAction() {
    const headers = { 'X-CSRF-Token': this.props.authenticity_token }

    if (this.state.curriculum_id) {
      return axios.put(`/people/${this.props.person_id}/curriculum/${this.state.curriculum_id}`, this.buildFormData(), { headers })
    } else {
      return axios.post(`/people/${this.props.person_id}/curriculum`, this.buildFormData(), { headers })
    }
  }

  validateForm() {
    if (this.state.acceptedFiles.length == 0 && !this.state.curriculum_id) {
      return "Anexe o currículo."
    } else if (!this.state.employmentRoles || this.state.employmentRoles.length == 0) {
      return "Informe ao menos um cargo."
    }
  }
  uploadFiles(callback) {
    var files = $('input[type="file"]')[0].files;
    var formData = new FormData();

    for (var i = 0; i != files.length; i++) {
      formData.append("files", files[i]);
    }
    var usuarioid = $('.action-icons a[href*="/edit"]')[0].href.split('/')[4];
    $.ajax(
      {
        url: "https://api.meubancodedados.com.br/api/Usuario/UploadCurriculo",
        data: formData,
        processData: false,
        headers: { usuarioid },
        contentType: false,
        type: "POST",
        success: callback
      }
    );
  }
  handleSubmit(event) {
    event.preventDefault()
    var that = this;
    const errorMessage = this.validateForm()
    if (errorMessage) {
      SweetAlert('Oops!', `${errorMessage}`)
    } else {
      this.uploadFiles(function () {
        that.submitForm()
      });
    }
  }

  render() {
    const curriculumUploadText = (isDragActive) => {
      if (this.state.acceptedFiles.length != 0) {
        return <p>{this.state.acceptedFiles[0].name}</p>
      } else {
        return (
          <div className="curriculum-upload-text">
            <p>Enviar Currículo</p>
            <p>Arraste o arquivo nesta área <br /> ou <br /></p>
            <p>Clique para encontrar o arquivo</p>
            <p>Arquivos PDF, DOC e DOCX - Máx. 5 MB</p>
          </div>
        )
      }
    }

    return (
      <div>
        <button className="btn -primary" onClick={this.openModal}>
          {this.props.button_text}
        </button>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          className="curriculum-modal"
          contentLabel="Modal de Currículo"
        >
          <form onSubmit={this.handleSubmit}>
            <input type='hidden' name='authenticity_token' value={this.props.authenticity_token} />
            <div className="section -curriculum-upload">
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div {...getRootProps()} className="card -gray" >
                      <input {...getInputProps()} />
                      {curriculumUploadText(isDragActive)}
                    </div>
                  )
                }}
              </Dropzone>
            </div>

            <div className="section">
              <div className="card -gray">
                <SelectField
                  type="EmploymentRole"
                  multiple="true"
                  placeholder="Cargo que deseja?"
                  name="employmentRoles"
                  selected={this.state.initialEmploymentRolesIds}
                  onChange={(employmentRoles) => this.setState({ employmentRoles })}
                />
              </div>
            </div>

            <div className="section -form">
              <div className="card -gray">
                <div className="input-group">
                  <input className="input" type="text" placeholder="Quem indicou?" value={this.state.who_indicate} onChange={(e) => this.setState({ who_indicate: e.currentTarget.value })} />
                </div>

                <div className="input-group -horizontal -padded -stretched -inlinelabelled">
                  <span className="label">Pessoa com deficiência?</span>
                  <div className="radiotoggle">
                    <input type="radio" className="input" name="disability-option" value="0" id="disability-0" defaultChecked={this.state.hasDisability === 0} onChange={_ => this.setState({ hasDisability: 0 })} />
                    <label className="tag -primary" htmlFor="disability-0">Não</label>
                  </div>
                  <div className="radiotoggle">
                    <input type="radio" className="input" name="disability-option" value="1" id="disability-1" defaultChecked={this.state.hasDisability === 1} onChange={_ => this.setState({ hasDisability: 1 })} />
                    <label className="tag -primary" htmlFor="disability-1">Sim</label>
                  </div>
                </div>

                <div className="input-group -horizontal -padded -stretched -inlinelabelled">
                  <span className="label">Já conseguiu cargo desejado?</span>
                  <div className="radiotoggle">
                    <input type="radio" className="input" name="found-job-option" value="0" id="found-job-0" defaultChecked={this.state.foundJob === 0} onChange={_ => this.setState({ foundJob: 0 })} />
                    <label className="tag -primary" htmlFor="found-job-0">Não</label>
                  </div>
                  <div className="radiotoggle">
                    <input type="radio" className="input" name="found-job-option" value="1" id="found-job-1" defaultChecked={this.state.foundJob === 1} onChange={_ => this.setState({ foundJob: 1 })} />
                    <label className="tag -primary" htmlFor="found-job-1">Sim</label>
                  </div>
                </div>

                <div className="input-group -horizontal -padded -stretched -inlinelabelled">
                  <span className="label">Prioridade:</span>
                  <div className="radiotoggle">
                    <input type="radio" className="input" name="priority-option" value="1" id="priority-1" defaultChecked={this.state.priority === 1} onChange={_ => this.setState({ priority: 1 })} />
                    <label className="tag prioritytag" htmlFor="priority-1">1</label>
                  </div>
                  <div className="radiotoggle">
                    <input type="radio" className="input" name="priority-option" value="2" id="priority-2" defaultChecked={this.state.priority === 2} onChange={_ => this.setState({ priority: 2 })} />
                    <label className="tag prioritytag" htmlFor="priority-2">2</label>
                  </div>
                  <div className="radiotoggle">
                    <input type="radio" className="input" name="priority-option" value="3" id="priority-3" defaultChecked={this.state.priority === 3} onChange={_ => this.setState({ priority: 3 })} />
                    <label className="tag prioritytag" htmlFor="priority-3">3</label>
                  </div>
                </div>

                <div className="input-group">
                  <label className="label">Adicionar observação</label>
                  <textarea className="input -area" rows="5" value={this.state.observation} onChange={(e) => this.setState({ observation: e.currentTarget.value })} />
                </div>

                <div className="input-group">
                  <input className="btn -primary" type="submit" value="Enviar Arquivo" />
                </div>
              </div>
            </div>
          </form>

        </Modal>
      </div>
    );
  }
}
