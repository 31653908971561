import './base-form'
import $ from 'jquery'

$(() => {
  $('[name="is_influencer"]').change(() => {
    const value = $('[name="is_influencer"]:checked').val();

    if (value === '1') {
      $('#influencer-type').slideDown()
    } else {
      $('#influencer-type').slideUp()
    }
  });

  $('#influencer-type').hide()
  $('[name="is_influencer"]').trigger('change')
})
