import React, { useState } from 'react'
import Timeline, { TimelineMarkers, TodayMarker } from 'react-calendar-timeline'
import Popover from 'react-popover'
import moment from 'moment'
import ProfilePhoto from './ProfilePhoto'
import If from './if'
import IcEdit from '../images/ic-edit'
import IcDelete from '../images/ic-delete'
import 'react-calendar-timeline/lib/Timeline.css'
import 'moment/locale/pt-br'

export default ({ calendars, events }) => {
  const groupMap = {}
  calendars.forEach((calendar) => groupMap[calendar.id] = calendar)

  const items = events.map((event) => ({
    ...event,
    start_time: moment(event.start_time),
    end_time: moment(event.end_time),
    canMove: false,
    popoverState: useState(false),
    itemProps: {
      style: {
        background: groupMap[event.group].background,
        color: groupMap[event.group].foreground
      }
    }
  }))

  const itemMap = {}
  items.forEach((item) => itemMap[item.id] = item)

  const groupRenderer = ({ group }) => {
    return (
      <a
        href={group.url}
        className="tag -large"
        style={{
          background: group.background,
          color: group.foreground,
          lineHeight: '1em'
        }}
      >
        {group.title}
      </a>
    )
  }

  const popoverBody = (item) => {
    return (
      <div className="popover -timeline">
        <div className="header">
          <h5>{item.title}</h5>
          <If test={item.start_time.isSame(item.end_time, 'day')}>
            <strong>
              <span>{item.start_time.format('DD/MM/YY')}, </span>
              <span>de {item.start_time.format('HH:mm')} </span>
              <span>às {item.end_time.format('HH:mm')}</span>
            </strong>
          </If>
          <If test={!item.start_time.isSame(item.end_time, 'day')}>
            <strong>
              <span>De {item.start_time.format('DD/MM/YY HH:mm')} até </span>
              <span>{item.end_time.format('DD/MM/YY HH:mm')}</span>
            </strong>
          </If>
        </div>

        <div className="section">
          <strong>Local:</strong>
          <span>{item.address}</span>
        </div>

        <div className="section">
          <strong>Criado por:</strong>

          <div className="profile">
            <div className="photo">
              <ProfilePhoto inline photoable={item.created_by} />
            </div>
            <div className="info">
              <span className="name">{item.created_by.fullname}</span>
            </div>
          </div>
        </div>

        <If test={item.guests.length > 0}>
          <div className="section">
            <strong>Convidados:</strong>
            {
              item.guests.map((guest) => (
                <div className="profile" key={guest.id}>
                  <div className="photo">
                    <ProfilePhoto inline photoable={guest} />
                  </div>
                  <div className="info">
                    <p className="name">{guest.fullname}</p>
                  </div>
                </div>
              ))
            }
          </div>
        </If>

        <div className="actions">
          <If test={item.editUrl}>
            <a href={item.editUrl}>
              <IcEdit/>
            </a>
          </If>
          <If test={item.deleteUrl}>
            <a
              href={item.deleteUrl}
              data-method="DELETE"
              data-confirm="Tem certeza que deseja deletar esse evento? Essa ação é irreversível."
            >
              <IcDelete/>
            </a>
          </If>
        </div>
      </div>
    )
  }

  const itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
    const [isOpen, setOpen] = item.popoverState

    return (
      <div {...getItemProps(item.itemProps)}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

        <Popover
          isOpen={isOpen}
          onOuterAction={() => setOpen(false)}
          body={popoverBody(item)}
        >
          <div
            className="rct-item-content"
            style={{ maxHeight: `${itemContext.dimensions.height}` }}
          >
            {itemContext.title}
          </div>
        </Popover>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
      </div>
    )
  }

  const onSelect = (itemId) => {
    const [isOpen, setOpen] = itemMap[itemId].popoverState
    setOpen(!isOpen)
  }

  return (
    <Timeline
      groups={calendars}
      items={items}
      defaultTimeStart={moment().add(-12, 'hour')}
      defaultTimeEnd={moment().add(12, 'hour')}
      groupRenderer={groupRenderer}
      itemRenderer={itemRenderer}
      lineHeight={45}
      onItemSelect={onSelect}
      onItemClick={onSelect}
    >
      <TimelineMarkers>
        <TodayMarker />
      </TimelineMarkers>
    </Timeline>
  )
}
