import React, { useState } from 'react'
import Popover from 'react-popover'
import ProfilePhoto from './ProfilePhoto'
import If from './if'
import IcEdit from '../images/ic-edit'
import IcDelete from '../images/ic-delete'
import moment from 'moment'
import 'react-calendar-timeline/lib/Timeline.css'
import 'moment/locale/pt-br'

export default ({ calendar, event }) => {
  const [isOpen, setOpen] =  useState(false)

  const startTime = moment(event.start_time)
  const endTime = moment(event.end_time)

  const popoverBody = (
    <div className="popover -timeline">
      <div className="header">
        <h5>{event.title}</h5>
        <If test={startTime.isSame(endTime, 'day')}>
          <strong>
            <span>{startTime.format('DD/MM/YY')}, </span>
            <span>de {startTime.format('HH:mm')} </span>
            <span>às {endTime.format('HH:mm')}</span>
          </strong>
        </If>
        <If test={!startTime.isSame(endTime, 'day')}>
          <strong>
            <span>De {startTime.format('DD/MM/YY HH:mm')} até </span>
            <span>{endTime.format('DD/MM/YY HH:mm')}</span>
          </strong>
        </If>
      </div>

      <div className="section">
        <strong>Local:</strong>
        <span>{event.address}</span>
      </div>

      <div className="section">
        <strong>Criado por:</strong>

        <div className="profile">
          <div className="photo">
            <ProfilePhoto inline photoable={event.created_by} />
          </div>
          <div className="info">
            <span className="name">{event.created_by.fullname}</span>
          </div>
        </div>
      </div>

      <If test={event.guests.length > 0}>
        <div className="section">
          <strong>Convidados:</strong>
          {
            event.guests.map((guest) => (
              <div className="profile" key={guest.id}>
                <div className="photo">
                  <ProfilePhoto inline photoable={guest} />
                </div>
                <div className="info">
                  <p className="name">{guest.fullname}</p>
                </div>
              </div>
            ))
          }
        </div>
      </If>

      <div className="actions">
        <If test={event.editUrl}>
          <a href={event.editUrl}>
            <IcEdit/>
          </a>
        </If>
        <If test={event.deleteUrl}>
          <a
            href={event.deleteUrl}
            data-method="DELETE"
            data-confirm="Tem certeza que deseja deletar esse evento? Essa ação é irreversível."
          >
            <IcDelete/>
          </a>
        </If>
      </div>
    </div>
  )

  return (
    <div
      className="tag -large"
      style={{ background: calendar.background, color: calendar.foreground, fontWeight: 'bold' }}
      onClick={() => setOpen(!isOpen)}
    >
      <Popover isOpen={isOpen} onOuterAction={() => setOpen(false)} body={popoverBody}>
        {event.title}
      </Popover>
    </div>
  )
}
