import React, { Component, Fragment } from 'react'
import UserSelect from './UserSelect'
import RequestDocuments from './RequestDocuments'
import If from './if'
import { SlideDown } from 'react-slidedown'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-slidedown/lib/slidedown.css'

import DatePickerInput from './DatePickerInput'
import SelectField from './SelectField'

import { ptBR } from 'date-fns/locale'
import moment  from 'moment'

registerLocale('pt-BR', ptBR)
setDefaultLocale('pt-BR')

export default class RequestFields extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: props.isRequestSpecified || props.formSent,
      dueDate: props.dueDate ? moment(props.dueDate) : moment().add(4, 'days')
    }
  }

  _hasErrors = (field) => Object.keys(this.props.errors[field] || {}).length > 0

  _inputModifier = (...fields) => {
    const { formSent } = this.props
    const inputModifier = !fields.every(this._hasErrors) ? '-success' : '-error'
    return formSent ? inputModifier : ''
  }

  _onToggle = (e) => {
    e.preventDefault()
    this.setState(prevState => ({ visible: !prevState.visible }))
  }

  _onChangeDueDate = (value) => {
    this.setState({ dueDate: moment(value) })
  }

  render() {
    const { visible, dueDate } = this.state
    const {
      title, description, attendanceTagIds, isRequestSpecified, errors, requestId
    } = this.props

    return (
      <Fragment>
            <div className="card">
              <div className={`input-group ${this._inputModifier('title')}`}>
                <label className="label">Título da Tarefa</label>
                <input
                  defaultValue={title}
                  className="input"
                  name="task[title]"
                />
              </div>
              <div className={`input-group ${this._inputModifier('description')}`}>
                <label className="label">Descrição da Tarefa</label>
                <textarea
                  defaultValue={description}
                  className="input -area"
                  name="task[description]"
                />
              </div>
              <div className={`input-group -horizontal -responsive-strech ${this._inputModifier('assignee')}`}>
                <div className="input-group -stretched">
                  <SelectField
                    type="Task"
                    multiple="true"
                    placeholder="Área da Tarefa"
                    name="task[task_tag_ids][]"
                    selected={attendanceTagIds}
                  />
                </div>
              </div>
              <div className={`input-group -horizontal -responsive-strech`}>
                <If test={!isRequestSpecified}>
                  <div className="input-group -horizontal -padded -stretched -inlinelabelled">
                    <span className="label">Data Limite - {this.state.startDate}</span>

                    <input
                      value={dueDate.format()}
                      type="hidden"
                      name="task[due_date]"
                    />

                    <DatePicker
                      selected={dueDate.toDate()}
                      onChange={this._onChangeDueDate}
                      locale="pt-BR"
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      customInput={<DatePickerInput />}
                      withPortal={window.innerWidth < 992}
                      popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport'
                        }
                      }}
                    />
                  </div>
                </If>
              </div>
            </div>
      </Fragment>
    )
  }
}
