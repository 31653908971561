/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


import 'styles/application.sass'
import 'cocoon-js'
import 'jquery-ujs'

// Async/Await support
import 'babel-polyfill'

import ReactRailsUJS from 'react_ujs'
ReactRailsUJS.useContext(require.context('components', true))

import Chartkick from "chartkick"
import Chart from "chart.js"
window.Chartkick = Chartkick
Chartkick.addAdapter(Chart)

window.addEventListener('DOMContentLoaded', () => {
    document.addEventListener('copy', (event) => {
        if (event.target.dataset.copy != "allow" && !window.disableClipboardEffect && window.location.href.indexOf('calendars') < 0) {
            event.clipboardData.setData('text/plain', 'Cópia desabilitada')
            event.preventDefault()
            window.disableClipboardEffect = false
        }
    });

    $('a[data-prompt]').on('click', function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();

        const link = $(this)
        const msg = link.data('prompt')
        const value = prompt(msg)

        if (!value) return

        const href = link.attr('href'),
              method = link.data('method'),
              csrfToken = $('meta[name=csrf-token]').attr('content'),
              csrfParam = $('meta[name=csrf-param]').attr('content')

        const form = $('<form method="post" action="' + href + '"></form>')
        const paramName = link.data('param-name') || 'prompt-value'
        const promptParamInput = '<input name="' + paramName + '" value="' + value + '" type="hidden" />'

        let metadataInput = '<input name="_method" value="' + method + '" type="hidden" />'
        if (csrfParam !== undefined && csrfToken !== undefined) {
            metadataInput += '<input name="' + csrfParam + '" value="' + csrfToken + '" type="hidden" />'
        }

        form.hide().append(metadataInput).append(promptParamInput).appendTo('body')
        form.submit()
    });
})
