import React, { Component } from 'react'
import SlideDown from 'react-slidedown'
import { List, Form } from './person-observations'
import Pager from './Pager'
import If from './if'

export default class AttendanceTimeline extends Component {
  state = { visible: true }

  _onToggle = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({ visible: !prevState.visible }))
  }

  render() {
    const { visible } = this.state
    const { data } = this.props

    return (
      <div className="attendance-timeline">
        <h4>
          <span>Atendimentos do Usuário</span>
          <button onClick={this._onToggle} className="btn -primary -small -spaced -withrighticon">
            {visible ? 'Ocultar' : 'Mostrar'}

            <span className="icon">
              {visible ? '▲' : '▼'}
            </span>
          </button>
        </h4>

        <div className="row">
          <SlideDown>
            <If test={visible}>
              <div className="data" dangerouslySetInnerHTML={{__html: data}}>
              </div>
            </If>
          </SlideDown>
        </div>
      </div>
    )
  }
}
