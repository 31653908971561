import React, { Component } from 'react'
import SweetAlert from 'sweetalert2'
import axios from 'axios'
import SlideDown from 'react-slidedown'

import If from '../if'
import ProfilePhoto from '../ProfilePhoto'

import IcDelete from '../../images/ic-delete'
import IcEdit from '../../images/ic-edit'

export default class Observation extends Component {
  state = { typingObservation: '', editing: false }

  _onEdit = (e) => {
    e.preventDefault()
    const { observation } = this.props
    this.setState({ typingObservation: observation.content, editing: true })
  }

  _onConfirmEdition = async (e) => {
    e.preventDefault()

    const { observation, personId, onEdit } = this.props
    const { typingObservation } = this.state

    const params = { person_observation: { content: typingObservation } }

    try {
      const res = await axios.patch(`/people/${personId}/observations/${observation.id}.json`, params)
      this.setState({ editing: false }, () => onEdit(res.data))
    } catch (_) {
      SweetAlert('Oops!', 'Ocorreu um erro para editar a observação. Tente novamente mais tarde.')
    }
  }

  _onCancelEdition = (e) => {
    e.preventDefault()
    this.setState({ editing: false })
  }

  _onDelete = async (e) => {
    e.preventDefault()

    const { personId, observation, onDelete } = this.props

    const result = await SweetAlert({
      title: 'Você tem certeza?',
      text: 'Deletar esta observação é uma ação irreversível.',
      showCancelButton: true,
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Voltar'
    })

    if (result.value) {
      try {
        await axios.delete(`/people/${personId}/observations/${observation.id}.json`)
        onDelete()
      } catch (_) {
        SweetAlert('Oops!', 'Ocorreu um erro para deletar a observação. Tente novamente mais tarde.')
      }
    }
  }

  render() {
    const { observation, canManage } = this.props
    const { typingObservation, editing } = this.state

    return (
      <tr className="line">
        <td className="cell">
          <div className="profile-photo">
            <ProfilePhoto photoable={observation.author} />
          </div>
          <div className="observation-content">
            <span className="author-name">{observation.author.fullname}</span>
            <br/>
            <span>{observation.author.role}</span>

            <br/>
            <br/>

            <SlideDown>
              <If test={editing}>
                <div className="card -gray">
                  <div className="input-group">
                    <label className="label">Editar observação</label>
                    <textarea
                      className="input -area"
                      value={typingObservation}
                      onChange={(e) => this.setState({ typingObservation: e.target.value })}
                    />
                  </div>

                </div>
                <div className="actions">
                  <button className="btn -small -spaced" onClick={this._onCancelEdition}>Cancelar</button>
                  <button className="btn -primary -small -spaced" onClick={this._onConfirmEdition}>Confirmar</button>
                </div>
              </If>
              <If test={!editing}>
                {observation.content}
              </If>
            </SlideDown>
          </div>
        </td>
        <td className="cell -right">
          <span className="">{observation.created_at}</span>

          <If test={canManage}>
            <div className="actions">
              <div className="action-icons">
                <button className="btn -link -inline" onClick={this._onEdit}>
                  <IcEdit />
                </button>
                <button className="btn -link -inline" onClick={this._onDelete}>
                  <IcDelete />
                </button>
              </div>
            </div>
          </If>
        </td>
      </tr>
    )
  }
}
