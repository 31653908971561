import $ from 'jquery'

$(() => {
  const updateCheckboxes = (suffix) => {
    let isAnyUnchecked = false
    let isAnyChecked = false

    $(`input[id^="destroy_${suffix}_"]`).each((_, el) => {
      isAnyChecked = isAnyChecked || el.checked
      isAnyUnchecked = isAnyUnchecked || !el.checked
    })

    $(`#check-destroy-${suffix}-all`).prop('checked', !isAnyUnchecked)

    if (isAnyChecked) {
      $(`#delete-${suffix}-all-button`).slideDown()
    } else {
      $(`#delete-${suffix}-all-button`).slideUp()
    }
  }

  ['mobile', 'desktop'].forEach((suffix) => {
    $(`#check-destroy-${suffix}-all`).on('change', (e) => {
      $(`input[id^="destroy_${suffix}_"]`).prop('checked', e.target.checked)
      updateCheckboxes(suffix)
    })

    $(`input[id^="destroy_${suffix}_"]`).on('change', () => updateCheckboxes(suffix))

    updateCheckboxes(suffix)
  })

  $('.tags-filter .toggle').click(() => {
    $('.tags-filter .list').slideToggle()
  })
})
