import React, { Component, Fragment } from 'react'
import MaskedInput from 'react-text-mask'
import If from './if'

export default class PhoneFields extends Component {
  constructor(props) {
    super(props)

    this.state = {
      phones: props.phones
    }
  }

  _onChangeNumber = (idx) => (e) => {
    const value = e.target.value
    this.setState((prevState) => {
      const { phones } = prevState
      phones[idx].number = value
      return { phones }
    })
  }

  _onChangeType = (idx) => (e) => {
    const value = e.target.value
    this.setState((prevState) => {
      const { phones } = prevState
      phones[idx].type = value
      return { phones }
    })
  }

  _phoneMask(rawValue) {
    let numbers = rawValue.replace(/\D/g, '');

    if (numbers.length > 10) {
      return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
  }

  _renderPhone = (phone, idx) => {
    const rawPhone = (phone.number || '').replace(/\D/g, '')
    const { errors, formSent } = this.props
    const hasError = Object.keys(errors[idx] || {}).length > 0
    const inputModifier = hasError ? '-error' : '-success'
    const showModifier = formSent && errors[idx] && rawPhone.length > 0

    return (
      <div key={idx} className={`input-group -horizontal ${showModifier ? inputModifier : ''}`}>
        <If test={phone.id}>
          <input type="hidden" name={`person[phones_attributes][${idx}][id]`} value={phone.id} />
        </If>
        <If test={phone.id && !rawPhone}>
          <input type="hidden" name={`person[phones_attributes][${idx}][_destroy]`} value="1" />
        </If>
        <input type="hidden" name={`person[phones_attributes][${idx}][number]`} value={rawPhone} />

        <label className="label">Telefone</label>
        <MaskedInput
          mask={this._phoneMask}
          value={rawPhone}
          className="input"
          onChange={this._onChangeNumber(idx)}
        />

        <select
          value={phone.type || ''}
          onChange={this._onChangeType(idx)}
          name={`person[phones_attributes][${idx}][type]`}
          className="select -nostretch"
        >
          <option value="landline">Fixo</option>
          <option value="cellphone">Celular</option>
          <option value="other">Outro</option>
        </select>
      </div>
    )
  }

  _onAddPhone = (e) => {
    e.preventDefault()
    this.setState(prevState => ({ phones: [...prevState.phones, { number: '', type: '' }] }))
  }

  render() {
    const { phones } = this.state

    return (
      <Fragment>
        {phones.map(this._renderPhone)}
        <div className="addphone">
          <a onClick={this._onAddPhone}>Adicionar nº de telefone</a>
        </div>
      </Fragment>
    )
  }
}
