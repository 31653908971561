import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import Dropzone from 'react-dropzone'
import MaskedInput from 'react-text-mask'

import SelectField from './SelectField'

import FormData from 'form-data'
import axios from 'axios'
import SweetAlert from 'sweetalert2'

// Modal.setAppElement('.profile')

export default class DocumentModal extends Component {
  static defaultProps = {
    buttonClass: "btn -primary -small"
  }

  constructor(props) {
    super(props)
    const fields = props.fields || {}
    const documentTypes = (fields.documentTypes || []).map(({ value }) => value)

    this.state = {
      modalIsOpen: false,
      acceptedFiles: [],
      initialDocumentTypesIds: documentTypes,
      title: fields.title,
      description: fields.description,
      number: fields.number,
      date: fields.date,
      documentTypes: fields.documentTypes,
      documentId: this.props.documentId,
      // curriculum_id: null
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.buildFormData = this.buildFormData.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.requestAction = this.requestAction.bind(this)
  }

  openModal(e) {
    e.preventDefault()
    this.setState({modalIsOpen: true})
  }

  closeModal() {
    this.setState({modalIsOpen: false})
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles[0].size > 20000000) {
      SweetAlert('Oops!', 'O arquivo não pode ser maior que 20 MB.')
    } else if (!this.validateFileFormat(acceptedFiles)) {
      SweetAlert('Oops!', 'O arquivo precisa ser um PDF, DOC, DOCX, XLS ou XLSX.')
    } else {
      this.setState({acceptedFiles})
    }
  }

  validateFileFormat(acceptedFiles) {
    const { name } = acceptedFiles[0]
    return (name.includes(".pdf") || name.includes(".doc") || name.includes(".docx") || name.includes(".xls") || name.includes(".xlsx"))
  }

  buildFormData() {
    const {acceptedFiles, title, description, number, date, documentTypes} = this.state
    const {requestId} = this.props

    let formData = new FormData()
    if (acceptedFiles[0])
      formData.append('document_file', acceptedFiles[0], acceptedFiles[0].name)

    formData.append('title', title)
    formData.append('description', description)
    formData.append('number', number)
    formData.append('posted_at', date)
    formData.append('document_types', JSON.stringify(documentTypes))
    formData.append('request_id', requestId)

    return formData
  }

  submitForm() {
    this.requestAction()
      .then(_ => window.location.reload())
      .catch(_ => SweetAlert('Oops!', 'Ocorreu um erro para salvar o documento. Tente novamente mais tarde.'))
  }

  requestAction() {
    const { authenticityToken, requestId } = this.props
    const { documentId } = this.state

    const headers = { 'X-CSRF-Token': authenticityToken }

    if (documentId) {
      return axios.put(`/request_documents/${documentId}`, this.buildFormData(), { headers })
    } else {
      return axios.post(`/request_documents`, this.buildFormData(), { headers })
    }
  }

  validateForm() {
    const { acceptedFiles, documentId, documentTypes, title } = this.state

    if (acceptedFiles.length == 0 && !documentId) {
      return "Anexe o documento."
    } else if(!documentTypes || documentTypes.length == 0 ) {
      return "Informe o tipo do documento."
    } else if(!title || title.length == 0 ) {
      return "Informe o título do documento."
    }
  }

  handleSubmit(event) {
    event.preventDefault()

    const errorMessage = this.validateForm()

    if (errorMessage) {
      SweetAlert('Oops!', `${errorMessage}`)
    } else {
      this.submitForm()
    }
  }

  render() {
    const {acceptedFiles, modalIsOpen, initialDocumentTypesIds, title, description, number, date} = this.state
    const {authenticityToken, buttonText, buttonClass } = this.props

    const curriculumUploadText = (isDragActive) => {
      if(acceptedFiles.length != 0) {
        return <p>{acceptedFiles[0].name}</p>
      } else {
        return (
          <div className="curriculum-upload-text">
            <p>Enviar Documento</p>
            <p>Arraste o arquivo nesta área <br/> ou <br/></p>
            <p>Clique para encontrar o arquivo</p>
            <p>Arquivos PDF, DOC, DOCX, XLS e XLSX - Máx. 10 MB</p>
          </div>
        )
      }
    }

    return (
      <div className="document-modal-div">
        <button className={buttonClass} onClick={this.openModal}>
          {buttonText}
        </button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          className="curriculum-modal"
          contentLabel="Modal de Documento"
        >
          <form onSubmit={this.handleSubmit}>
            <input type='hidden' name='authenticity_token' value={authenticityToken} />
            <div className="section -curriculum-upload">
              <Dropzone onDrop={this.onDrop}>
                {({getRootProps, getInputProps, isDragActive}) => {
                  return (
                    <div {...getRootProps()} className="card -gray" >
                      <input {...getInputProps()} />
                      {curriculumUploadText(isDragActive)}
                    </div>
                  )
                }}
              </Dropzone>
            </div>

            <div className="section">
              <div className="card -gray">
                <SelectField
                  type="DocumentType"
                  multiple="true"
                  placeholder="Tipo do Documento *"
                  name="documentTypes"
                  selected={initialDocumentTypesIds}
                  onChange={(documentTypes) => this.setState({documentTypes})}
                />
              </div>
            </div>

            <div className="section -form">
              <div className="card -gray">
                <div className="input-group">
                  <label className="label" htmlFor="document_title">Título do Documento *</label>
                  <input
                    id="document_title"
                    className="input"
                    type="text"
                    value={title}
                    onChange={(e) => this.setState({title: e.currentTarget.value})}
                  />
                </div>
                <div className="input-group">
                  <label className="label" htmlFor="document_description">Descrição do Documento</label>
                  <input
                    id="document_description"
                    className="input"
                    type="text"
                    value={description}
                    onChange={(e) => this.setState({description: e.currentTarget.value})}
                  />
                </div>
                <div className="input-group">
                  <label className="label" htmlFor="document_number">Nº do Documento</label>
                  <input
                    id="document_number"
                    className="input"
                    type="text"
                    value={number}
                    onChange={(e) => this.setState({number: e.currentTarget.value})}
                  />
                </div>
                <div className="input-group">
                  <label className="label" htmlFor="document_date">Data do Documento</label>
                  <MaskedInput
                    mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                    id="document_date"
                    className="input"
                    type="text"
                    value={date}
                    onChange={(e) => this.setState({date: e.currentTarget.value})}
                  />
                </div>
                <div className="input-group">
                  <input className="btn -primary" type="submit" value="Enviar Arquivo" />
                </div>
              </div>
            </div>
          </form>

        </Modal>
      </div>
    );
  }
}
