export default {
  control: (provided, _state) => ({
    ...provided,
    borderRadius: 0,
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    padding: 6
  }),
  menu: (provided, _state) => ({
    ...provided,
    marginTop: 0,
    borderRadius: 0,
    boxShadow: '0 4px 11px rgba(0, 0, 0, 0.1)'
  }),
  container: (provided, _state) => ({
    ...provided,
    border: 'none',
    fontSize: 14
  }),
  multiValue: (provided, _state) => ({
    ...provided,
    borderRadius: 3,
    cursor: 'pointer',
    margin: '2px 5px',
    textTransform: 'uppercase'
  }),
  multiValueLabel: (provided, _state) => ({
    ...provided,
    padding: '5px 10px',
    fontSize: 10
  }),
  singleValue: (provided, _state) => ({
    ...provided,
    lineHeight: 20
  })
}
