import React, { Component } from 'react'
import SlideDown from 'react-slidedown'
import { List, Form } from './task-observations'
import Pager from './Pager'
import If from './if'

export default class TaskObservations extends Component {
  state = { visible: true }

  _onToggle = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({ visible: !prevState.visible }))
  }

  render() {
    const { visible } = this.state
    const {
      personId,
      isTaskSpecified,
      dueDate,
      requestStatus,
      attendanceStatus,
      requestId,
      canManage
    } = this.props

    return (
      <div className="request-observations">
        <h4 className="title">
          <span>Observações da Tarefa</span>
          <button onClick={this._onToggle} className="btn -primary -small -spaced -withrighticon">
            {visible ? 'Ocultar' : 'Mostrar'}

            <span className="icon">
              {visible ? '▲' : '▼'}
            </span>
          </button>
        </h4>

        <SlideDown>
          <If test={visible}>
            <Pager
              endpoint={`/tasks/${requestId}/observations.json`}
              resource="observations"
              listComponent={List}
              listProps={{ personId, canManage }}
              formComponent={Form}
              formProps={{
                dueDate,
                requestStatus,
                attendanceStatus,
                isTaskSpecified,
                requestId
              }}
            />
          </If>
        </SlideDown>
      </div>
    )
  }
}
