import React from 'react'

export default () => (
  <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-324.000000, -244.000000)">
        <g transform="translate(322.000000, 244.000000)">
          <g transform="translate(7.421423, 6.862666) rotate(-315.000000) translate(-7.421423, -6.862666) translate(5.181423, -0.497334)">
            <rect stroke="#979797" strokeWidth="1.152" x="0.576" y="0.576" width="2.87326251" height="11.4553856" rx="0.64"></rect>
            <polygon fill="#979797" points="-4.97379915e-14 12.1501159 4.02526251 12.1501159 2.01263126 14.3867385"></polygon>
          </g>
          <path d="M11.84,4.16 L10.56,2.88" id="Line" stroke="#979797" strokeWidth="0.64" strokeLinecap="square"></path>
        </g>
      </g>
    </g>
  </svg>

)
