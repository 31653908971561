import React, { Component } from 'react'
import SweetAlert from 'sweetalert2'
import axios from 'axios'

import If from '../if'

import IcDelete from '../../images/ic-delete'
import IcEdit from '../../images/ic-edit'

export default class RequestDocument extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: true,
    }
  }

  _onDelete = async(e) => {
    const { document } = this.props
    e.preventDefault()

    const result = await SweetAlert({
      title: 'Certeza?',
      text: 'Tem certeza que deseja excluir este documento?',
      showCancelButton: true,
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Voltar'
    })

    if (result.value) {
      try {
        await axios.delete(`/request_documents/${document.id}.json`)
        this.setState({ visible: false })
      } catch (_) {
        SweetAlert('Oops!', 'Ocorreu um erro para deletar o documento. Tente novamente mais tarde.')
      }
    }
  }

  render() {
    const { document, canManageDocument } = this.props
    const { visible } = this.state

    return visible && (
      <tr className="line">
        <td className="cell inlinelink">
          <a href={document.url} target="_blank" rel="noopener noreferrer">{ document.title }</a>
        </td>
        <td className="cell -centered">
          <span>{ document.document_types[0].label }</span>
        </td>
        <td className="cell -centered">
          <span>{ document.description }</span>
        </td>
        <td className="cell -centered">
          <span>{ document.number }</span>
        </td>
        <td className="cell -centered">
          <span>{ document.created_by }</span>
        </td>
        <td className="cell -centered">
          <span>{ document.posted_at }</span>
        </td>
        <td className="cell -centered">
          <span>{ document.created_at }</span>
        </td>
        <td className="cell -right">
          <If test={canManageDocument}>
            <div className="action-icons">
              <DocumentModal
                documentId={document.id}
                requestId={requestId}
                authenticityToken={authenticityToken}
                buttonText="📄 Anexar novo documento"
              />
              <button className="btn -link -inline" onClick={this._onDelete}>
                <IcDelete/>
              </button>
            </div>
          </If>
        </td>
      </tr>
    )
  }
}
