import React, { Component, Fragment } from 'react'
import Tooltip from 'react-tooltip-lite'

export default class TagCloud extends Component {
  renderTag = (tag) => {
    return (
      <span key={tag} className="tag -primary">{tag}</span>
    )
  }

  renderVisibleTags = () => {
    const { tags, maxVisible } = this.props
    return tags.slice(0, maxVisible).map(this.renderTag)
  }

  renderTooltipContent = () => {
    const { tags, maxVisible } = this.props

    return (
      <div className="card -padded -withoutafterpad">
        {tags.slice(maxVisible).map(this.renderTag)}
      </div>
    )
  }

  renderTagPopup = () => {
    const { tags, maxVisible } = this.props

    if (tags.length > maxVisible) {
      return (
        <Tooltip
          content={this.renderTooltipContent()}
          tagName="span"
          eventToggle="onClick"
        >
          <span className="tag">...</span>
        </Tooltip>
      )
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderVisibleTags()}
        {this.renderTagPopup()}
      </Fragment>
    )
  }
}
