import React from 'react'

export default () => (
  <svg width="22px" height="26px" viewBox="0 0 22 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Dashboard-Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Perfil-(mobile)" transform="translate(-31.000000, -259.000000)" stroke="#FFFFFF" strokeWidth="1.92">
        <g id="Group-2" transform="translate(32.310345, 260.091954)">
          <path d="M19.3103448,20.0455126 C19.3103448,15.0180734 14.9875769,10.9425287 9.65517241,10.9425287 C4.32276793,10.9425287 0,15.0180734 0,20.0455126 C0,25.0729517 19.3103448,25.0729517 19.3103448,20.0455126 Z" id="Oval-11"></path>
          <ellipse id="Oval-12" cx="9.97701149" cy="5.47126437" rx="4.82758621" ry="5.47126437"></ellipse>
        </g>
      </g>
    </g>
  </svg>
)
