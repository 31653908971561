import React, { Component, Fragment } from 'react'
import axios from 'axios'
import If from './if'

export default class Pager extends Component {
  state = {
    elements: [],
    total: 0,
    page: 1
  }

  componentDidMount() {
    this._loadPage()
  }

  _loadPage = async (e) => {
    const { endpoint, resource } = this.props
    const { page } = this.state

    e && e.preventDefault()

    const res = await axios.get(endpoint, { params: { page } })
    const data = res.data

    this.setState(prevState => ({
      elements: [...prevState.elements, ...data[resource]],
      total: data.total,
      page: prevState.page + 1,
    }))
  }

  _renderLoadMore = () => {
    const { elements, total } = this.state

    return (
      <If test={elements.length}>
        <div className="loadmore">
          <If test={elements.length < total}>
            <button className="btn -link" onClick={this._loadPage}>Ver Mais</button>
          </If>

            <p className="count">
              (visualizando {elements.length} de {total})
            </p>
        </div>
      </If>
    )
  }

  _onCreateElement = (element) => {
    this.setState(({ elements, total }) => ({
      elements: [element, ...elements],
      total: total + 1
    }))
  }

  _onEditElement = (index, element) => {
    this.setState(({ elements }) => {
      elements[index] = element
      return { elements}
    })
  }

  _onDeleteElement = (index) => {
    this.setState(({ elements, total }) => {
      elements.splice(index, 1)
      return { elements, total: total - 1 }
    })
  }

  render() {
    const { listComponent, listProps, formComponent, formProps } = this.props
    const { elements } = this.state

    return (
      <Fragment>
        {
          React.createElement(
            listComponent,
            {
              ...listProps,
              elements,
              onDelete: this._onDeleteElement,
              onEdit: this._onEditElement
            }
          )
        }
        {this._renderLoadMore()}
        {React.createElement(formComponent, { ...formProps, onCreate: this._onCreateElement })}
      </Fragment>
    )
  }
}
