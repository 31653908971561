import $ from 'jquery'

$(() => {
  const $sidebarToggle = $('#sidebar-toggle')
  const $sidebar = $('#sidebar')

  $sidebarToggle.on('click', () => {
    $sidebar.toggleClass('-open')
    $sidebarToggle.toggleClass('-open')
  })
})
