import React, { Component, Fragment } from 'react'
import MaskedInput from 'react-text-mask'
import CepPromise from 'cep-promise'
import SlideDown from 'react-slidedown'
import SelectField from './SelectField'
import If from './if'

export default class AddressFields extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cep: props.cep,
      addressComplement: props.addressComplement,
      addressNumber: props.addressNumber,
      streetName: props.streetName,
      cityLabel: props.cityLabel,
      neighborhoodLabel: props.neighborhoodLabel,
      stateLabel: props.stateLabel,
      searchByCep: !!props.streetName && !!props.cityLabel && !!props.stateLabel && !!props.cep,
    }
  }

  _hasErrors = (field) => Object.keys(this.props.errors[field] || {}).length > 0

  _inputModifier = (field) => {
    const { formSent } = this.props
    const inputModifier = this._hasErrors(field) ? '-error' : '-success'
    return formSent ? inputModifier : ''
  }

  _rawCep = () => (this.state.cep || '').replace(/\D/g, '')

  _updateAddressData = ({ state, city, street, neighborhood }) => {
    this.setState((prevState) => {
      const updates = {
        stateLabel: state,
        cityLabel: city,
        neighborhoodLabel: neighborhood,
        searchByCep: true
      }

      if (!prevState.streetName)
        updates.streetName = street

      return updates
    })
  }

  onChangeCep = (e) => {
    this.setState({ cep: e.target.value }, () => {
      const cep = this._rawCep()

      if (cep.length == 8) {
        CepPromise(cep).then(this._updateAddressData)
      } else {
        this.setState({ searchByCep: false })
      }
    })
  }

  render() {
    const {
      streetName,
      addressNumber,
      addressComplement,
      cityLabel,
      neighborhoodLabel,
      stateLabel,
      searchByCep
    } = this.state

    const { cityId, neighborhoodId, stateId } = this.props
    const cep = this._rawCep()
    const labelChangeFn = (option) => option ? option.label : ''

    return (
      <Fragment>
        <div className={`input-group ${this._inputModifier('cep')}`}>
          <label className="label">CEP</label>
          <MaskedInput
            className="input"
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            value={cep}
            onChange={this.onChangeCep}
          />
          <input type="hidden" name="person[cep]" value={cep} />
        </div>
        <div className={`input-group ${this._inputModifier('street_name')}`}>
          <label className="label">Endereço</label>
          <textarea
            className="input -area"
            type="text"
            name="person[street_name]"
            value={streetName || ''}
            onChange={(e) => this.setState({ streetName: e.target.value })}
          />
        </div>
        <div className={`input-group ${this._inputModifier('address_number')}`}>
          <label className="label">Número</label>
          <textarea
            className="input -area"
            type="text"
            name="person[address_number]"
            value={addressNumber || ''}
            onChange={(e) => this.setState({ addressNumber: e.target.value })}
          />
        </div>
        <div className={`input-group ${this._inputModifier('address_complement')}`}>
          <label className="label">Complemento</label>
          <textarea
            className="input -area"
            type="text"
            name="person[address_complement]"
            value={addressComplement || ''}
            onChange={(e) => this.setState({ addressComplement: e.target.value })}
          />
        </div>
        <div className="input-group -forcedivisory">
          <SlideDown>
            <If test={searchByCep}>
              <label className="label">UF</label>
              <input className="input" name="person[state_label]" readOnly value={stateLabel} />
            </If>
          </SlideDown>
          <SlideDown>
            <If test={!searchByCep}>
              <SelectField
                name="person[state_label]"
                type="State"
                placeholder="UF"
                selected={stateId}
                valueFn={labelChangeFn}
              />
            </If>
          </SlideDown>
        </div>
        <div className="input-group -forcedivisory">
          <SlideDown>
            <If test={searchByCep}>
              <label className="label">Cidade</label>
              <input className="input" name="person[city_label]" readOnly value={cityLabel} />
            </If>
          </SlideDown>
          <SlideDown>
            <If test={!searchByCep}>
              <SelectField
                name="person[city_label]"
                type="City"
                placeholder="Cidade"
                selected={cityId}
                valueFn={labelChangeFn}
              />
            </If>
          </SlideDown>
        </div>
        <div className="input-group -forcedivisory">
          <SlideDown>
            <If test={searchByCep}>
              <label className="label">Bairro</label>
              <input className="input" name="person[neighborhood_label]" readOnly value={neighborhoodLabel} />
            </If>
          </SlideDown>
          <SlideDown>
            <If test={!searchByCep}>
              <SelectField
                name="person[neighborhood_label]"
                type="Neighborhood"
                placeholder="Bairro"
                selected={neighborhoodId}
                valueFn={labelChangeFn}
              />
            </If>
          </SlideDown>
        </div>
      </Fragment>
    )
  }
}
