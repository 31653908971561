import React, { Component } from 'react'
import IcProfile from '../images/ic-profile'
import If from './if'

export default class ProfilePhoto extends Component {
  render() {
    const { large, inline, photoable } = this.props

    return (
      <div className={`profile-picture ${large ? '-large' : ''} ${inline ? '-inline' : ''}`}>
        <div className="photo">
          <If test={photoable.photo}>
            <img src={photoable.photo} />
          </If>
          <If test={!photoable.photo}>
            <IcProfile/>
          </If>
        </div>
      </div>

    )
  }
}
