import React from 'react'

export default (props) => (
  <svg width="11px" height="13px" viewBox="0 0 11 13" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-294.000000, -245.000000)">
        <g transform="translate(294.000000, 245.000000)">
          <rect stroke="#979797" strokeWidth="0.64" fill="#D8D8D8" fillRule="evenodd" x="0.32" y="2.24" width="10.24" height="1" rx="0.5"></rect>
          <path d="M2.88,0.96 L8,0.96 L8,0.64 C8,0.46326888 7.85673112,0.32 7.68,0.32 L3.2,0.32 C3.02326888,0.32 2.88,0.46326888 2.88,0.64 L2.88,0.96 Z" stroke="#979797" strokeWidth="0.64" fill="#D8D8D8" fillRule="evenodd"></path>
          <rect stroke="#979797" strokeWidth="1.28" x="1.92" y="2.56" width="7.04" height="9.6" rx="0.64"></rect>
          <rect fill="#979797" fillRule="evenodd" x="1.92" y="9.6" width="7.04" height="1.92"></rect>
        </g>
      </g>
    </g>
  </svg>
)
