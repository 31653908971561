import React, { Component, Fragment } from "react";
import UserSelect from "./UserSelect";
import RequestDocuments from "./RequestDocuments";
import If from "./if";
import { SlideDown } from "react-slidedown";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-slidedown/lib/slidedown.css";

import DatePickerInput from "./DatePickerInput";
import SelectField from "./SelectField";

import { ptBR } from "date-fns/locale";
import moment from "moment";

registerLocale("pt-BR", ptBR);
setDefaultLocale("pt-BR");

export default class RequestFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible:
        props.isRequestSpecified ||
        props.formSent ||
        props.requestWithoutAttendance,
      dueDate: props.dueDate ? moment(props.dueDate) : moment().add(4, "days"),
    };
  }

  _hasErrors = (field) =>
    Object.keys(this.props.errors[field] || {}).length > 0;

  _inputModifier = (...fields) => {
    const { formSent } = this.props;
    const inputModifier = !fields.every(this._hasErrors)
      ? "-success"
      : "-error";
    return formSent ? inputModifier : "";
  };

  _onToggle = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ visible: !prevState.visible }));
  };

  _onChangeDueDate = (value) => {
    this.setState({ dueDate: moment(value) });
  };

  render() {
    const { visible, dueDate } = this.state;
    const {
      title,
      description,
      assigneeId,
      attendanceTagIds,
      isRequestSpecified,
      authenticityToken,
      documents,
      errors,
      requestId,
      requestWithoutAttendance,
    } = this.props;

    return (
      <Fragment>
        <h4>
          <span>Demanda do Atendimento</span>
          <button
            onClick={this._onToggle}
            className="btn -primary -small -spaced -withrighticon"
          >
            {visible ? "Ocultar Criação de Demanda" : "Criar Demanda"}

            <span className="icon">{visible ? "▲" : "▼"}</span>
          </button>
        </h4>

        <SlideDown>
          <If test={visible}>
            <div className="card">
              <div className={`input-group ${this._inputModifier("title")}`}>
                <label className="label">Título da Demanda</label>
                <input
                  defaultValue={title}
                  className="input"
                  name="request[title]"
                />
              </div>
              <div
                className={`input-group ${this._inputModifier("description")}`}
              >
                <label className="label">Descrição da Demanda</label>
                <textarea
                  defaultValue={description}
                  className="input -area"
                  name="request[description]"
                />
              </div>
              <div
                className={`input-group -horizontal -responsive-strech ${this._inputModifier(
                  "assignee"
                )}`}
              >
                <div className="input-group -stretched">
                  <UserSelect
                    assignableOnly
                    selected={assigneeId}
                    placeholder="Responsável pela demanda"
                    name="request[assignee_id]"
                  />
                </div>
                <div className="input-group -stretched">
                  <SelectField
                    type="Attendance"
                    multiple="true"
                    placeholder="Área da Demanda"
                    name="request[attendance_tag_ids][]"
                    selected={attendanceTagIds}
                  />
                </div>
              </div>
              <div className={`input-group -horizontal -responsive-strech`}>
                <If test={!isRequestSpecified || requestWithoutAttendance}>
                  <div className="input-group -horizontal -padded -stretched -inlinelabelled">
                    <span className="label">
                      Data Limite - {this.state.startDate}
                    </span>

                    <input
                      value={dueDate.format()}
                      type="hidden"
                      name="request[due_date]"
                    />

                    <DatePicker
                      selected={dueDate.toDate()}
                      onChange={this._onChangeDueDate}
                      locale="pt-BR"
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      customInput={<DatePickerInput />}
                      withPortal={window.innerWidth < 992}
                      popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: "viewport",
                        },
                      }}
                    />
                  </div>
                </If>
              </div>
            </div>

            <RequestDocuments
              isRequestSpecified={isRequestSpecified}
              requestId={requestId}
              errors={errors}
              documents={documents}
              authenticityToken={authenticityToken}
            />
          </If>
        </SlideDown>
      </Fragment>
    );
  }
}
