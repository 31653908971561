import React, { Component } from 'react'
import { GoogleApiWrapper } from 'google-maps-react'
import MarkerClusterer from 'node-js-marker-clusterer'
import axios from 'axios'
import qs from 'qs'

class SearchMap extends Component {
  static DEFAULT_COORDINATES = { lat: -15.7754462, lng: -47.7970891 }

  map = null

  componentDidMount() {
    this.loadMap()
  }

  createMarker = (person) => {
    const latLng = new google.maps.LatLng(person.coordinates[1], person.coordinates[0])
    const infowindow = new google.maps.InfoWindow({ content: `${person.name} ${person.surname}` })
    const marker = new this.props.google.maps.Marker({ position: latLng })
    marker.addListener('click', () => infowindow.open(this.map, marker))
    return marker
  }

  createMarkers = async(markerCluster, page = 1) => {
    const people = await this.fetchPeople(page)

    if (people.length === 0)
      return

    const markers = people.filter(person => (person.coordinates && person.coordinates.length !== 0))
                          .map(this.createMarker)

    markerCluster.addMarkers(markers)
    this.createMarkers(markerCluster, page + 1)
  }

  fetchPeople = async(page) => {
    const { filters } = this.props
    const querystring = qs.stringify({ ...filters, page }, { arrayFormat: 'brackets' })
    const res = await axios.get(`/people/search/result.json?${querystring}`)
    return res.data
  }

  loadMap = () => {
    this.map = new this.props.google.maps.Map(this.refs.googleMap, {
      center: SearchMap.DEFAULT_COORDINATES,
      zoom: 10
    })

    const markerCluster = new MarkerClusterer(this.map, [], {
      imagePath: 'https://raw.githubusercontent.com/googlemaps/js-marker-clusterer/gh-pages/images/m'
    })

    this.createMarkers(markerCluster)
  }

  render() {
    return <div ref='googleMap' style={{ height: '500px' }} />
  }
}

export default class WrappedSearchMap extends Component {
  constructor(props) {
    super(props)

    this.WrappedComponent = GoogleApiWrapper({
      apiKey: props.apiKey
    })(SearchMap)
  }

  render() {
    return <this.WrappedComponent {...this.props} />
  }
}
