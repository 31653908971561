import React, { Component, Fragment } from 'react'
import UserSelect from './UserSelect'
import If from './if'
import { SlideDown } from 'react-slidedown'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-slidedown/lib/slidedown.css'

import DatePickerInput from './DatePickerInput'
import SelectField from './SelectField'
import DocumentModal from './DocumentModal'

import { ptBR } from 'date-fns/locale'
import moment from 'moment'

import IconDelete from '../images/ic-delete'

registerLocale('pt-BR', ptBR)
setDefaultLocale('pt-BR')

export default class RequestDocuments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: true, //props.isRequestSpecified || props.formSent,

    }
  }

  _hasErrors = (field) => Object.keys(this.props.errors[field] || {}).length > 0

  _inputModifier = (...fields) => {
    const { formSent } = this.props
    const inputModifier = !fields.every(this._hasErrors) ? '-success' : '-error'
    return formSent ? inputModifier : ''
  }

  _onToggle = (e) => {
    e.preventDefault()
    this.setState(prevState => ({ visible: !prevState.visible }))
  }

  _renderDocuments = () => {
    const { documents, requestId, authenticityToken } = this.props

    return documents.map((document, index) => (
      <tr key={index} className="line">
        <td className="cell inlinelink">
          <a href={document.url} target="_blank" rel="noopener noreferrer">{document.title}</a>
        </td>
        <td className="cell -centered">
          {
            document.document_types.map(({ value, label }) => (
              <span key={value} className="tag">{label}</span>
            ))
          }
        </td>
        <td className="cell -centered">
          <span>{document.description}</span>
        </td>
        <td className="cell -centered">
          <span>{document.number}</span>
        </td>
        <td className="cell -centered">
          <span>{document.created_by}</span>
        </td>
        <td className="cell -right">
          <span>{document.received_at}</span>
        </td>
        <td className="cell -right">
          <DocumentModal
            documentId={document.id}
            requestId={requestId}
            authenticityToken={authenticityToken}
            buttonText="✏️ Editar"
            buttonClass="btn -link -withrighticon"
            fields={{
              documentTypes: document.document_types,
              description: document.description,
              number: document.number,
              date: document.posted_at,
              title: document.title
            }}
          />

          <a
            className="btn -link -withrighticon"
            data-confirm="Tem certeza que deseja remover este documento?"
            data-method="delete"
            href={`/request_documents/${document.id}`}
          >
            <span className="icon"><IconDelete/></span>
            <span>Remover</span>
          </a>
        </td>
      </tr>
    ))
  }

  render() {
    const { visible } = this.state
    const { isRequestSpecified, requestId, title, documents, authenticityToken } = this.props

    if (!isRequestSpecified)
      return null

    return (
      <Fragment>
        <h4>
          <span>Documentos da Demanda</span>

          <DocumentModal
            documentId='5cec2c7a2c1cd300044c5adb'
            requestId={requestId}
            authenticityToken={authenticityToken}
            buttonText='📄 Anexar novo documento'
          />

          <button onClick={this._onToggle} className="btn -primary -small -spaced -withrighticon">
            {visible ? 'Ocultar' : 'Mostrar'}

            <span className="icon">
              {visible ? '▲' : '▼'}
            </span>
          </button>
        </h4>

        <SlideDown>
          <div className="data">
            <div className="request-list" id="attendances-table">
              <div className="table">
                <table className="content">
                  <If test={visible && documents.length > 0}>
                    <thead>
                      <tr className="line">
                        <td className="cell">Título</td>
                        <td className="cell -centered">Tipo</td>
                        <td className="cell -centered">Descrição</td>
                        <td className="cell -centered">Nº</td>
                        <td className="cell -centered">Anexado por</td>
                        <td className="cell -right">Data</td>
                      </tr>
                    </thead>
                    <tbody>
                      { this._renderDocuments() }
                    </tbody>
                  </If>
                  <If test={visible && documents.length == 0}>
                    <tbody>
                      <tr className="line">
                        <td className="cell">
                          Esta demanda não possui documentos
                        </td>
                      </tr>
                    </tbody>
                  </If>
                </table>
              </div>
            </div>
          </div>
        </SlideDown>
      </Fragment>
    )
  }
}
