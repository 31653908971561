import React, { Component } from 'react'
import SlideDown from 'react-slidedown'
import Observation from './Observation'

export default class List extends Component {
  render() {
    const { elements, canManage, onEdit, onDelete } = this.props
    const validObservations = elements.filter(el => el.content !== "" && el.content !== null);

    return (
      <SlideDown>
        <div className="table">
          <table className="content">
            <thead>
              <tr className="line">
                <td className="cell"></td>
                <td className="cell -right">Criado</td>
              </tr>
            </thead>

            <tbody>
              {
                validObservations.length > 0 ? (
                  validObservations.map((observation, idx) => (
                    <Observation
                      key={idx}
                      observation={observation}
                      canManage={canManage}
                      onEdit={(obs) => onEdit(idx, obs)}
                      onDelete={() => onDelete(idx)}
                    />
                  ))
                ) : (
                  <tr className="line">
                    <td className="cell">
                      Esse usuário não possui observações
                    </td>
                    <td className="cell"></td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </SlideDown>
    )
  }
}
