import React, { Component } from 'react'
import SweetAlert from 'sweetalert2'
import axios from 'axios'
import SlideDown from 'react-slidedown'

import If from '../if'
import ProfilePhoto from '../ProfilePhoto'

import IcDelete from '../../images/ic-delete'
import IcEdit from '../../images/ic-edit'

export default class Observation extends Component {

  tagModifierByStatus = {
    pending: '-warning',
    fulfilled: '-positive',
    not_fulfilled: '-negative',
    attended: '-positive',
    cancelled: '-negative',
  }

  tagNameByStatus = {
    pending: 'Pendente',
    fulfilled: 'Atividade Executada',
    not_fulfilled: 'Atividade Não Executada',
    attended: 'Atendido',
    cancelled: 'Cancelado',
  }

  state = {
    editing: false,
    typingObservation: ''
  }

  _onConfirmEdition = async(e) => {
    e.preventDefault()

    const { observation, onEditObservation } = this.props
    const { typingObservation } = this.state
    const params = { observation: { content: typingObservation } }

    try {
      const res = await axios.patch(`/tasks/${observation.task_id}/observations/${observation.id}.json`, params)
      this.setState({ editing: false }, () => onEditObservation(res.data))
    } catch (_) {
      SweetAlert('Oops!', 'Ocorreu um erro para editar a observação. Tente novamente mais tarde.')
    }
  }

  _onCancelEdition = (e) => {
    e.preventDefault()
    this.setState({ editing: false })
  }

  _onEdit = (e) => {
    const { observation } = this.props
    e.preventDefault()
    this.setState({ editing: true, typingObservation: observation.content })
  }

  _onDelete = async(e) => {
    const { observation, onDelete } = this.props
    e.preventDefault()
    console.log(observation)
    const result = await SweetAlert({
      title: 'Você tem certeza?',
      text: 'Deletar esta observação é uma ação irreversível.',
      showCancelButton: true,
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Voltar'
    })

    if (result.value) {
      try {
        await axios.delete(`/tasks/${observation.task_id}/observations/${observation.id}.json`)
        onDelete()
      } catch (_) {
        SweetAlert('Oops!', 'Ocorreu um erro para deletar a observação. Tente novamente mais tarde.')
      }
    }
  }

  render() {
    const { editing, typingObservation } = this.state
    const { observation, canManageObservation } = this.props

    return (
      <div className="observation" key={observation.id}>
        <div className="body">
          <div className="author">
            <div className="photo">
              <ProfilePhoto inline photoable={observation.author} />
            </div>
            <div className="info">
              <p className="name">{observation.author.fullname}</p>
              <p className="role">{observation.author.rolename}</p>
            </div>
          </div>

          <div className="content">
            <SlideDown>
              <If test={editing}>
                <div className="card">
                  <div className="input-group">
                    <label className="label">Editar observação</label>
                    <textarea
                      className="input -area"
                      value={typingObservation}
                      onChange={(e) => this.setState({ typingObservation: e.target.value })}
                      />
                  </div>

                </div>
                <div className="editactions">
                  <button className="btn -small -spaced" onClick={this._onCancelEdition}>Cancelar</button>
                  <button className="btn -primary -small -spaced" onClick={this._onConfirmEdition}>Confirmar</button>
                </div>
              </If>
              <If test={!editing}>
                {observation.content}
              </If>
            </SlideDown>
          </div>

          <div className="statuses">
            <If test={observation.attendance_task_status}>
              <div className="status">
                <label>Status da Tarefa</label>
                <span className={`tag ${this.tagModifierByStatus[observation.attendance_task_status]}`}>
                  {this.tagNameByStatus[observation.attendance_task_status]}
                </span>
              </div>
            </If>

            <If test={observation.status}>
              <div className="status">
                <label>Status da Atividade</label>
                <span className={`tag ${this.tagModifierByStatus[observation.status]}`}>
                  {this.tagNameByStatus[observation.status]}
                </span>
              </div>

              <If test={observation.status === 'pending'}>
                <div className="status">
                  <label>Data limite</label>
                  <span className="tag -primary">{observation.due_date}</span>
                </div>
              </If>
            </If>
          </div>
        </div>

        <div className="date">
          <p>{observation.created_at}</p>

          <If test={canManageObservation}>
            <div className="action-icons">
              <button className="btn -link -inline" onClick={this._onEdit}>
                <IcEdit/>
              </button>
              <button className="btn -link -inline" onClick={this._onDelete}>
                <IcDelete/>
              </button>
            </div>
          </If>
        </div>
      </div>
    )
  }
}
