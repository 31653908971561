import React from 'react'

export default () => (
  <svg width="23px" height="25px" viewBox="0 0 23 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Dashboard-Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-71.000000, -128.000000)" fill="#686868">
        <path d="M92.1111111,146.162272 L78.8888889,146.162272 C77.8456844,146.162272 77,145.258769 77,144.144242 L77,130.01803 C77,128.903503 77.8456844,128 78.8888889,128 L92.1111111,128 C93.1543156,128 94,128.903503 94,130.01803 L94,144.144242 C94,145.258769 93.1543156,146.162272 92.1111111,146.162272 L92.1111111,146.162272 Z M92,130.136738 L79,130.136738 L79,144.025534 L92,144.025534 L92,130.136738 L92,130.136738 Z M83.4938099,138.949306 L84.6315238,140.174628 L88.1245051,132.829112 C88.2537977,132.576178 88.4729735,132.389922 88.7322926,132.312614 C88.9916117,132.235306 89.2690833,132.273502 89.5017377,132.418533 C89.983453,132.73697 90.1424482,133.407455 89.8610158,133.933612 L85.9289168,142.226454 C85.891014,142.395339 85.8117818,142.550349 85.6993781,142.675524 C85.5110893,142.879194 85.2470632,142.980948 84.9808219,142.952451 C84.553301,142.984417 84.1627719,142.692873 84.0427069,142.252115 L82.2263567,140.30828 C81.9110053,139.927522 81.9269894,139.349506 82.2628275,138.989417 C82.5986656,138.629329 83.1381666,138.611749 83.4938099,138.949306 L83.4938099,138.949306 Z M72.8888889,150.554455 L86.1111111,150.554455 L86.1111111,148.536425 L88,148.536425 L88,150.554455 C88,151.668983 87.1543156,152.572486 86.1111111,152.572486 L72.8888889,152.572486 C71.8456844,152.572486 71,151.668983 71,150.554455 L71,136.428244 C71,135.313717 71.8456844,134.410214 72.8888889,134.410214 L74.7777778,134.410214 L74.7777778,136.428244 L72.8888889,136.428244 L72.8888889,150.554455 L72.8888889,150.554455 Z" id="checkboxes"></path>
      </g>
    </g>
  </svg>
)
