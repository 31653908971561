import React, { Component } from 'react'
import { Circle, GoogleApiWrapper, Map, Marker } from 'google-maps-react'

class SurveyMapLocation extends Component {
  static DEFAULT_COORDINATES = { lat: -15.793994200339656, lng: -47.882776307215224 }

  constructor(props) {
    super(props)
    this.state = {
      radius: props.radius || 500,
      center: props.center || { ...SurveyMapLocation.DEFAULT_COORDINATES }
    }
  }

  onClick = (_mapProps, _map, clickEvent) => {
    this.setState({
      center: {
        lat: clickEvent.latLng.lat(),
        lng: clickEvent.latLng.lng()
      }
    })
  }

  render() {
    return (
      <>
        <div className="input-group">
          <label className="label" htmlFor="survey_radius">Raio a partir do centro: {this.state.radius}m.</label>
          <span style={{ padding: '8px 16px' }}>
            * Haverá tolerância de 5% para lidar com diferentes níveis de precisão dos dispositivos que estiverem respondendo o formulários.
          </span>
          <div className="input">
            <input
              style={{ width: '100%'}}
              type="range"
              min="500"
              max="50000"
              id="survey_radius"
              name="survey[radius]"
              step="500"
              value={this.state.radius}
              onChange={(e) => this.setState({ radius: e.target.value })}
            />
          </div>
        </div>

        <div className="input-group">
          <label className="label">Clique no mapa abaixo para selecionar o centro da pesquisa</label>
          <div style={{ position: 'relative', width: '100%', height: '500px', paddingTop: 8 }}>
            <input type="hidden" name="survey[center][lat]" value={this.state.center.lat} />
            <input type="hidden" name="survey[center][lng]" value={this.state.center.lng} />
            <Map
              google={this.props.google}
              zoom={10}
              initialCenter={SurveyMapLocation.DEFAULT_COORDINATES}
              onClick={this.onClick}
            >
              <Marker position={this.state.center} />
              <Circle
                radius={parseInt(this.state.radius, 10)}
                center={this.state.center}
                strokeColor="transparent"
                strokeOpacity={0}
                strokeWeight={5}
                fillColor="#FF0000"
                fillOpacity={0.2}
                onClick={this.onClick}
              />
            </Map>
          </div>
        </div>
      </>
    )
  }
}

export default class WrappedSurveyMapLocation extends Component {
  constructor(props) {
    super(props)

    this.WrappedComponent = GoogleApiWrapper({
      apiKey: props.apiKey
    })(SurveyMapLocation)
  }

  render() {
    return <this.WrappedComponent {...this.props} />
  }
}
