import React, { Component } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import SlideDown from 'react-slidedown'
import DatePickerInput from '../DatePickerInput'
import IcInfo from '../../images/ic-info'
import SweetAlert from 'sweetalert2'
import moment from 'moment'
import { ptBR } from 'date-fns/locale'
import If from '../if'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR')

export default class Form extends Component {
  constructor(props) {
    super(props)

    this.state = {
      creationVisible: true,
      typingObservation: '',
      dueDate: props.dueDate ? moment(props.dueDate) : moment().add(4, 'days'),
      requestStatus: props.requestStatus ? props.requestStatus : props.attendanceStatus,
      attendanceStatus: props.attendanceStatus
    }
  }

  _onChangeDueDate = (value) => {
    this.setState({ dueDate: moment(value) })
  }

  _onClickCreateObservation = async(e) => {
    const { requestId, onCreate } = this.props
    const { typingObservation, dueDate, requestStatus, attendanceStatus } = this.state

    e.preventDefault()

    const shouldCreate = this.state.creationVisible

    if (!shouldCreate) {
      this.setState(prevState => ({ creationVisible: !prevState.creationVisible }))
      return
    }

    if (!typingObservation || typingObservation.length == 0) {
      SweetAlert({ type: 'error', title: 'Oops!', text: 'Preencha a observação' })
      return
    }

    const params = {
      content: typingObservation,
      due_date: dueDate.format('YYYY-MM-DDThh:mm:ssZ'),
      status: requestStatus,
      attendance_task_status: attendanceStatus,
    }

    try {
      const res = await axios.post(`/tasks/${requestId}/observations.json`, params)
      this.setState({ creationVisible: false, typingObservation: '' }, () => onCreate(res.data))
    } catch (_) {
      SweetAlert('Oops!', 'Ocorreu um erro para salvar a observação. Tente novamente mais tarde.')
    }
  }

  render() {
    const { creationVisible, typingObservation, dueDate, requestStatus, attendanceStatus } = this.state
    const { isTaskSpecified } = this.props

    return (
      <div className="newobservation">
        <SlideDown>
          <If test={creationVisible}>
            <div className="card">
              <div className="input-group">
                <label className="label">Nova observação</label>
                <textarea
                  className="input -area"
                  value={typingObservation}
                  onChange={(e) => this.setState({ typingObservation: e.target.value })}
                />
              </div>
              <div className="input-group -horizontal -responsive-strech">
                <If test={isTaskSpecified}>
                  <div className="input-group -centered -horizontal -padded -stretched">
                    <span className="question">Status da atividade</span>
                    <div className="radiotoggle">
                      <input
                        type="radio"
                        className="input"
                        id="request_pending"
                        checked={requestStatus == 'pending'}
                        onChange={() => this.setState({ requestStatus: 'pending' })}
                      />
                      <label className="tag -warning" htmlFor="request_pending">Pendente</label>
                    </div>

                    <div className="radiotoggle">
                      <input
                        type="radio"
                        className="input"
                        id="request_fulfilled"
                        checked={requestStatus == 'fulfilled'}
                        onChange={() => this.setState({ requestStatus: 'fulfilled' })}
                      />
                      <label className="tag -positive" htmlFor="request_fulfilled">Atividade Executada</label>
                    </div>

                    <div className="radiotoggle">
                      <input
                        type="radio"
                        className="input"
                        id="request_not_fulfilled"
                        checked={requestStatus == 'not_fulfilled'}
                        onChange={() => this.setState({ requestStatus: 'not_fulfilled' })}
                      />
                      <label className="tag -negative" htmlFor="request_not_fulfilled">Atividade Não Executada</label>
                    </div>
                  </div>

                  <If test={requestStatus === 'pending'}>
                    <div className="input-group -centered -horizontal -padded -small">
                      <span className="question">Alterar Data Limite</span>

                      <DatePicker
                        selected={dueDate.toDate()}
                        onChange={this._onChangeDueDate}
                        locale="pt-BR"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        customInput={<DatePickerInput />}
                        withPortal={window.innerWidth < 992}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport'
                          }
                        }}
                      />
                    </div>
                  </If>
                </If>
              </div>
            </div>
          </If>
        </SlideDown>

        <div className="actions">
          <button className="btn -primary -withrighticon" onClick={this._onClickCreateObservation}>
            <span className="icon"><IcInfo /></span>
            {creationVisible ? 'Salvar observação da tarefa' : 'Criar nova observação'}
          </button>
        </div>
      </div>
    )
  }
}
