import React, { Component } from 'react'
import axios from 'axios'
import SweetAlert from 'sweetalert2'

export default class Form extends Component {
  state = { typingObservation: '' }

  _onSubmit = async (e) => {
    e.preventDefault()

    const { typingObservation } = this.state
    const { personId, onCreate } = this.props

    if (!typingObservation || typingObservation.length == 0) {
      SweetAlert({ type: 'error', title: 'Oops!', text: 'Preencha a observação' })
      return
    }

    const params = { person_observation: { content: typingObservation } }

    try {
      const res = await axios.post(`/people/${personId}/observations.json`, params)
      this.setState({ typingObservation: '' }, () => onCreate(res.data))
    } catch (_) {
      SweetAlert('Oops!', 'Ocorreu um erro para salvar a observação. Tente novamente mais tarde.')
    }
  }

  render() {
    const { typingObservation } = this.state

    return (
      <form className="observation-area" onSubmit={this._onSubmit}>
        <div className="card -gray">
          <div className="input-group">
            <textarea
              rows="5"
              placeholder="Digite Aqui"
              className="input -area"
              value={typingObservation}
              onChange={(e) => this.setState({ typingObservation: e.target.value })}
            />
          </div>
        </div>

        <div className="actions -right">
          <button type="submit" className="btn -primary">Salvar observação do Usuário</button>
        </div>
      </form>
    )
  }
}
