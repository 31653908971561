import React, { Component } from 'react'
import { Creatable as SelectCreatable } from 'react-select'
import ReactSelectStyles from './styles/react-select'
import IcTags from '../images/ic-tags'
import axios from 'axios'
import SweetAlert from 'sweetalert2'

export default class TagCreation extends Component {
  state = {
    selected: []
  }

  handleChange = (selected) => {
    this.setState({ selected })
  }

  onSubmitTags = () => {
    const { selected } = this.state
    const { tagsType } = this.props
    const labels = selected.map(({ label }) => label)

    axios.post('/tags.json', { type: tagsType, labels })
      .then(_ => window.location.reload())
      .catch(_ => SweetAlert('Oops!', 'Ocorreu um erro para salvar as tags. Tente novamente mais tarde.'))
  }

  render() {
    return (
      <div className="tags-form">
        <div className="card -withoutafterpad">
          <div className="input-group">
            <label className="label">Novas tags</label>
            <SelectCreatable
              isMulti
              placeholder=""
              styles={ReactSelectStyles}
              onChange={this.handleChange}
              noOptionsMessage={() => null}
              formatCreateLabel={(opt) => `Criar tag "${opt}"`}
            />
          </div>
        </div>

        <button className="btn -primary -withrighticon" onClick={this.onSubmitTags}>
          <span className="icon"><IcTags/></span>
          <span>Criar nova tag</span>
        </button>
      </div>
    )
  }
}
