import React, { Component } from "react";
import { Creatable as SelectCreatable } from "react-select";
import ReactSelectStyles from "./styles/react-select";
import IcTags from "../images/ic-tags";
import axios from "axios";
import SweetAlert from "sweetalert2";

export default class QueueCreation extends Component {
  state = {
    selected: []
  };

  handleChange = selected => {
    this.setState({ selected });
  };

  onSubmitTags = () => {
    const { selected } = this.state;
    const queues = selected.map(({ value }) => ({ name: value }));

    const headers = { "X-CSRF-Token": this.props.authenticity_token };

    axios
      .post("/requests_queues.json", { requests_queues: queues }, { headers })
      .then(_ => window.location.reload())
      .catch(_ =>
        SweetAlert(
          "Oops!",
          "Ocorreu um erro para salvar as filas. Tente novamente mais tarde."
        )
      );
  };

  render() {
    return (
      <div className="tags-form">
        <div className="card -withoutafterpad">
          <div className="input-group">
            <label className="label">Novas filas</label>
            <SelectCreatable
              isMulti
              placeholder=""
              styles={ReactSelectStyles}
              onChange={this.handleChange}
              noOptionsMessage={() => null}
              formatCreateLabel={opt => `Criar tag "${opt}"`}
            />
          </div>
        </div>

        <button
          className="btn -primary -withrighticon"
          onClick={this.onSubmitTags}
        >
          <span className="icon">
            <IcTags />
          </span>
          <span>Criar nova fila</span>
        </button>
      </div>
    );
  }
}
