import React from 'react'

export default () => (
  <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dashboard-Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1130.000000, -2562.000000)" fill="#FFFFFF">
              <g id="info" transform="translate(1130.000000, 2562.000000)">
                  <path d="M10.5,21 C4.70101013,21 -5.68434189e-14,16.2989899 -5.68434189e-14,10.5 C-5.68434189e-14,4.70101013 4.70101013,0 10.5,0 C16.2989899,0 21,4.70101013 21,10.5 C21,16.2989899 16.2989899,21 10.5,21 L10.5,21 Z M10.5,1.75 C5.66750844,1.75 1.75,5.66750844 1.75,10.5 C1.75,15.3324916 5.66750844,19.25 10.5,19.25 C15.3324916,19.25 19.25,15.3324916 19.25,10.5 C19.25,8.17935571 18.3281276,5.95375898 16.6871843,4.31281566 C15.046241,2.67187235 12.8206443,1.75 10.5,1.75 L10.5,1.75 Z M10.5,15.75 C10.0191445,15.7466339 9.63111322,15.3558673 9.631125,14.875 L9.631125,9.63375 C9.6210667,9.31691358 9.78435488,9.01974359 10.0571895,8.85835043 C10.3300241,8.69695727 10.6691009,8.69695727 10.9419355,8.85835043 C11.2147701,9.01974359 11.3780583,9.31691358 11.368,9.63375 L11.368,14.875 C11.3693965,15.1062774 11.2786196,15.3285868 11.1157369,15.492783 C10.9528542,15.6569792 10.7312811,15.7495389 10.5,15.75 L10.5,15.75 Z M10.5,7 C10.1488474,7.00035398 9.83207462,6.78909716 9.69744897,6.46477596 C9.56282333,6.14045477 9.63686959,5.76696907 9.88504688,5.51854135 C10.1332242,5.27011363 10.506635,5.19569072 10.8310918,5.32998919 C11.1555486,5.46428767 11.3671248,5.78084718 11.367125,6.132 C11.3661641,6.61064327 10.9786421,6.99855636 10.5,7 L10.5,7 Z M11.368,6.125 L11.368,6.13375 C11.3679151,6.1322929 11.3679151,6.1308321 11.368,6.129375 C11.3680849,6.1279179 11.368,6.125 11.368,6.125 L11.368,6.125 Z"></path>
              </g>
          </g>
      </g>
  </svg>
)
