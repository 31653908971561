import React, { Component } from 'react'
import { Circle, GoogleApiWrapper, Map, Marker } from 'google-maps-react'

class SurveyMapReport extends Component {
  renderMarker(center, radius, fillColor) {
    return [
      <Marker position={center} />,
      <Circle
        radius={parseInt(radius, 10)}
        center={center}
        strokeColor="transparent"
        strokeOpacity={0}
        strokeWeight={5}
        fillColor={fillColor}
        fillOpacity={0.2}
      />
    ]
  }

  render() {
    return (
      <div style={{ position: 'relative', width: '100%', height: '500px' }}>
        <Map
          google={this.props.google}
          zoom={10}
          initialCenter={this.props.center}
          onClick={this.onClick}
        >
          {this.renderMarker(this.props.center, this.props.radius, '#FF0000')}
          {
            this.props.answerCoords.map(({ center, radius }, index) => (
              this.renderMarker(center, radius, '#00FF00', index)
            ))
          }
        </Map>
      </div>
    )
  }
}

export default class WrappedSurveyMapReport extends Component {
  constructor(props) {
    super(props)

    this.WrappedComponent = GoogleApiWrapper({
      apiKey: props.apiKey
    })(SurveyMapReport)
  }

  render() {
    return <this.WrappedComponent {...this.props} />
  }
}
